import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { RtdGroup, RtdUserWithPermissions } from 'react-shared-library/types';

import emailRegex from '../common/emailRegex';

interface EditUserDialogProps {
  group: RtdGroup;
  user: RtdUserWithPermissions;
  open: boolean;
  onEdit: (email: string, name: string, isManager: boolean) => void;
  onCancel?: () => void;
}

export default function EditUserDialog(props: EditUserDialogProps) {
  const [email, setEmail] = useState(props.user.email);
  const [name, setName] = useState(props.user.details?.name);
  const [isManager, setIsManager] = useState(
    !!props.user.permissions.find(
      (permission) => permission.role === 'ORG_MANAGER'
    )
  );

  const valid = name && email && email.match(emailRegex);

  return (
    <Dialog open={props.open}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Email"
          fullWidth
          value={email}
          type="email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          margin="dense"
          label="Name"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <FormControlLabel
          label="Manager"
          control={
            <Checkbox
              checked={isManager}
              onChange={(event) => setIsManager(event.target.checked)}
            ></Checkbox>
          }
        ></FormControlLabel>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button
          onClick={() => props.onEdit(email!, name!, isManager)}
          variant="contained"
          disabled={!valid}
        >
          Edit User
        </Button>
      </DialogActions>
    </Dialog>
  );
}
