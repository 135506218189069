import Box from '@mui/material/Box';
import React from 'react';

import NoValueIndicator from './NoValueIndicator';

interface RenderedValueProps {
  children?: React.ReactNode;
  endAdornments?: React.ReactNode;
}

// Renders either the value passed in to it as a child, or a visual indicator
// that no value is available
//
//      const i = null;
//      return <RenderedValue>{i}</RenderedValue>
//
// Will display a grey ― to indicate no value
export default function RenderedValue(props: RenderedValueProps) {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
        {props.children ? props.children : <NoValueIndicator />}
        <Box sx={{ px: 1 }}>{props.endAdornments}</Box>
      </Box>
    </React.Fragment>
  );
}
