import './index.css';

import { ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import ProvideAuth from 'react-shared-library/auth/ProvideAuth';
import ProvideServices from 'react-shared-library/rtdapi-sdk/ProvideServices';

import analytics from './analytics';
import App from './App';
import { ProvideReportService } from './common/report-service';
import { getRtdApiUrl } from './environment';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ProvideAuth>
          <ProvideReportService rtdApiUrl={getRtdApiUrl()}>
            <ProvideServices rtdApiUrl={getRtdApiUrl()}>
              <App />
            </ProvideServices>
          </ProvideReportService>
        </ProvideAuth>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

analytics();
