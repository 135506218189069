import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { RtdWorkflow } from '../../types';

interface WorkflowTableProps {
  workflows: RtdWorkflow[] | null;
  onSelectWorkflow?: (workflow: RtdWorkflow) => any;
  fields?: string[];
}

export default function WorkflowTable(props: WorkflowTableProps) {
  if (!props.workflows) {
    return (
      <React.Fragment>
        {Array.from({ length: 10 }, (_, i) => (
          <Skeleton key={i} height={60} />
        ))}
      </React.Fragment>
    );
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>ID</TableCell>
          <TableCell>Created At</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.workflows &&
          props.workflows.map((workflow) => (
            <TableRow
              key={workflow.id}
              hover
              role="button"
              onClick={() => {
                if (!window.getSelection()?.isCollapsed) return;
                props.onSelectWorkflow && props.onSelectWorkflow(workflow);
              }}
            >
              <TableCell>{workflow.name}</TableCell>
              <TableCell>{workflow.id}</TableCell>
              <TableCell>{workflow.createdAt.toLocaleDateString()}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
