import { createContext } from 'react';

import {
  IGroupService,
  ISignedInUserContext,
  IUserService,
  RtdEntitlement,
  RtdGroupWithSubgroups,
  RtdProfile,
  RtdUserWithPermissions,
} from '../types';

const signedInUserContext = createContext<ISignedInUserContext | null>(null);
export default signedInUserContext;

export class AppUserContext implements ISignedInUserContext {
  entitlements: null | RtdEntitlement[] = null;

  // A local cache of the signed in users groups
  private groupsCache: { [groupId: string]: RtdGroupWithSubgroups } | null =
    null;
  private permissionsCache: {
    [groupId: string]: RtdUserWithPermissions['permissions'];
  } = {};
  private pendingPermissionsRequest: boolean = false;
  private pendingGroupsRequest: boolean = false;

  constructor(
    protected userService: IUserService,
    protected groupService: IGroupService,
    public user: RtdProfile
  ) {}

  // Returns the groups the user is a member of
  async getMyGroups(): Promise<RtdGroupWithSubgroups[]> {
    // If the permissions are in the cache, return that
    if (this.groupsCache) return Object.values(this.groupsCache);

    if (this.pendingGroupsRequest) {
      return new Promise((resolve) =>
        setTimeout(() => resolve(this.getMyGroups()), 50)
      );
    }
    this.pendingGroupsRequest = true;

    const groups = await this.userService.getGroupsForUser(this.user.id);
    this.groupsCache = groups.reduce(
      (cache, group) => ({ ...cache, [group.id]: group }),
      {}
    );
    return groups;
  }

  async isGroupAdmin(groupId: string): Promise<boolean> {
    const permissions = await this.getPermissions(groupId);
    return !!permissions.find((p) => p.role === 'ORG_ADMIN');
  }

  async isManagementAuthorized(groupId: string): Promise<boolean> {
    const isGroupAdmin = await this.isGroupAdmin(groupId);
    const isOwner = await this.isOwner(groupId);
    return isGroupAdmin || isOwner;
  }

  async isOwner(groupId: string): Promise<boolean> {
    await this.getMyGroups();
    let ownerId = this.groupsCache![groupId]?.ownerId;
    if (!ownerId) {
      // Look in each groups subgroups. This is not very well optimized, prioritizing simplicity and getting this done fast on my final weeks at RTD!
      // - Caleb
      const subgroup = Object.values(this.groupsCache ?? {}).find((g) =>
        g.subgroups.find((subg) => subg.id === groupId)
      );
      ownerId = subgroup?.ownerId;
    }
    if (ownerId) {
      const isDirectOwner = ownerId === this.user.id;
      const isGroupOwner = !!this.groupsCache![ownerId];
      return isDirectOwner || isGroupOwner;
    }
    return false;
  }

  hasEntitlement(entitlement: string): boolean {
    return !!this.getEntitlement(entitlement);
  }

  getEntitlement(entitlement: string): RtdEntitlement | null {
    return (
      this.user.entitlements
        .filter((e) => {
          if (e.expiry) return Date.now() > new Date(e.expiry).valueOf();
          return true;
        })
        .find((e) => e.entitlement === entitlement) || null
    );
  }

  showLegacyReports(): boolean {
    return (
      this.user.enableLegacyReports ||
      this.hasEntitlement('enableLegacyReports')
    );
  }

  private async getPermissions(
    groupId: string
  ): Promise<RtdUserWithPermissions['permissions']> {
    // If the permissions are in the cache, return that
    if (this.permissionsCache[groupId]) return this.permissionsCache[groupId];

    if (this.pendingPermissionsRequest) {
      return new Promise((resolve) =>
        setTimeout(() => resolve(this.getPermissions(groupId)), 50)
      );
    }
    this.pendingPermissionsRequest = true;

    // Otherwise, fetch the permissions
    const users: RtdUserWithPermissions[] =
      await this.groupService.getUsersForGroup(groupId);
    this.pendingPermissionsRequest = false;
    const me = users.find((user) => user.id === this.user.id);
    this.permissionsCache[groupId] = me?.permissions ?? [];
    return this.permissionsCache[groupId];
  }
}
