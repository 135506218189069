import React from 'react';

import authContext from './authContext';
import useProvideAuth from './useProvideAuth';

interface ProvideAuthProps {
  children: React.ReactNode;
}

export default function ProvideAuth(props: ProvideAuthProps) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>{props.children}</authContext.Provider>
  );
}

const defaultProps = {
  authenticated: false,
};

export function ProvideMockAuth(
  props: ProvideAuthProps & { authenticated: boolean } & typeof defaultProps
) {
  return (
    <authContext.Provider
      value={{
        credentials: props.authenticated
          ? { userId: 'MOCK_USER', accessToken: 'MOCK_TOKEN' }
          : null,
        login: jest.fn(),
        logout: jest.fn(),
      }}
    >
      {props.children}
    </authContext.Provider>
  );
}
ProvideMockAuth.defaultProps = defaultProps;
