import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

import { RtdGroup, RtdGroupWithSubgroups } from '../../types';
import { isUUID } from '../common/uuidRegex';

interface SubGroupTableProps {
  groups: RtdGroupWithSubgroups[] | null;
  showNested?: boolean;
  onSelectGroup?: (group: RtdGroup) => any;
}

export default function SubGroupTable(props: SubGroupTableProps) {
  if (!props.groups) {
    return (
      <React.Fragment>
        {Array.from({ length: 10 }, (_, i) => (
          <Skeleton key={i} height={60} />
        ))}
      </React.Fragment>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Group Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.groups.map((group) => (
            <React.Fragment key={group.id}>
              <TableRow
                hover
                role="button"
                onClick={() => {
                  if (!window.getSelection()?.isCollapsed) return;
                  props.onSelectGroup && props.onSelectGroup(group);
                }}
              >
                <TableCell>
                  {isUUID(group.name) ? <i>Deckhand Group</i> : group.name}
                </TableCell>
              </TableRow>
              {!!props.showNested &&
                group.subgroups.map((subgroup) => (
                  <TableRow
                    key={subgroup.id}
                    hover
                    role="button"
                    onClick={() => {
                      if (!window.getSelection()?.isCollapsed) return;
                      props.onSelectGroup && props.onSelectGroup(subgroup);
                    }}
                  >
                    <TableCell>
                      ↳&nbsp;
                      {isUUID(subgroup.name) ? (
                        <i>Deckhand Group</i>
                      ) : (
                        subgroup.name
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
