import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { RtdGroup } from 'react-shared-library/types';
import emailRegex from '../common/emailRegex';

interface CreateUserDialogProps {
  group: RtdGroup;
  open: boolean;
  onCreate: (email: string, password: string, name: string) => void;
  onCancel?: () => void;
}

export default function CreateUserDialog(props: CreateUserDialogProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  const valid = email.match(emailRegex) && password.length && name.length;

  return (
    <Dialog open={props.open}>
      <DialogTitle>Create User</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Email"
          fullWidth
          value={email}
          type="email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          margin="dense"
          label="Name"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          margin="dense"
          label="Password"
          fullWidth
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button
          onClick={() => props.onCreate(email, password, name)}
          variant="contained"
          disabled={!valid}
        >
          Create New User
        </Button>
      </DialogActions>
    </Dialog>
  );
}
