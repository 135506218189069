import './App.css';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { ThemeProvider } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import authContext from 'react-shared-library/auth/authContext';
import RouteGuard from 'react-shared-library/auth/RouteGuard';
import AppDrawer from 'react-shared-library/components/common/AppDrawer';
import UserService from 'react-shared-library/rtdapi-sdk/user-service';
import { RtdGroup, ISignedInUserContext } from 'react-shared-library/types';

import { getAuthUrl } from './environment';
import GroupHomeContainer from './group/GroupHomeContainer';
import ViewGroupContainer from './group/ViewGroupContainer';
import NavigationList from './listItems';
import ViewProfileContainer from './profile/ViewProfileContainer';
import { darkTheme } from './theme';
import ViewWorkflowContainer from './workflow/ViewWorkflowContainer';
import WorkflowHomeContainer from './workflow/WorkflowHomeContainer';
import userContext, {
  AppUserContext,
} from 'react-shared-library/context/user-context';
import GroupService from 'react-shared-library/rtdapi-sdk/group-service';
const drawerWidth: number = 240;

export default function App() {
  const handleUnauthenticated = () => {
    const target = new URL(getAuthUrl());
    target.searchParams.append('authRedirect', window.location.href);
    window.location.href = target.href;
  };
  const auth = useContext(authContext);
  const userService = useContext(UserService.context);
  const groupService = useContext(GroupService.context);

  const [signedInUserCtx, setSignedInUserCtx] =
    useState<ISignedInUserContext | null>(null);
  const [group, setGroup] = useState<RtdGroup | null>(null);

  useEffect(() => {
    if (!auth?.credentials?.accessToken) return;
    if (!userService || !groupService) return;
    // TODO: This should be moved to ISignedInUserContext to resolve and cache (see how groups are done)
    userService
      .getProfile()
      .then((user) =>
        setSignedInUserCtx(new AppUserContext(userService, groupService, user))
      );
  }, [groupService, userService, auth]);

  return (
    <userContext.Provider value={signedInUserCtx}>
      <BrowserRouter>
        <RouteGuard onUnauthenticated={handleUnauthenticated}>
          <Routes>
            <Route
              path="*"
              element={
                <Box sx={{ display: 'flex' }}>
                  <CssBaseline />
                  <ThemeProvider theme={darkTheme}>
                    <AppDrawer open drawerWidth={drawerWidth}>
                      <img
                        alt="Logo"
                        src="/Deckhand_logo__horiz_white.svg"
                        style={{ height: '57px' }}
                      />
                      <Divider />
                      <List component="nav">
                        <NavigationList group={group ?? undefined} />
                      </List>
                    </AppDrawer>
                  </ThemeProvider>
                  <Box
                    component="main"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[100]
                          : theme.palette.grey[900],
                      flexGrow: 1,
                      height: '100vh',
                      overflow: 'auto',
                      zIndex: '1',
                    }}
                  >
                    <Routes>
                      <Route
                        path="/groups/:groupId/*"
                        element={
                          <ViewGroupContainer onGroupChange={setGroup} />
                        }
                      />
                      <Route
                        path="/workflows"
                        element={<WorkflowHomeContainer />}
                      />
                      <Route
                        path="/workflows/:workflowId"
                        element={<ViewWorkflowContainer />}
                      />
                      <Route
                        path="/profile"
                        element={<ViewProfileContainer />}
                      />
                      <Route path="*" element={<GroupHomeContainer />} />
                    </Routes>
                  </Box>
                </Box>
              }
            ></Route>
          </Routes>
        </RouteGuard>
      </BrowserRouter>
    </userContext.Provider>
  );
}
