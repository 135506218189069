import { createContext, useContext } from 'react';
import authContext from 'react-shared-library/auth/authContext';
import { ProvideServicesProps } from 'react-shared-library/rtdapi-sdk/ProvideServices';
import { request } from 'react-shared-library/rtdapi-sdk/request';

export function ProvideReportService(props: ProvideServicesProps) {
  const auth = useContext(authContext);

  let reportService: ReportService | null = null;
  if (auth?.credentials) {
    reportService = new ReportService(
      props.rtdApiUrl,
      auth.credentials.accessToken
    );
  }

  return (
    // eslint-disable-next-line
    <ReportService.context.Provider value={reportService}>
      {props.children}
    </ReportService.context.Provider>
  );
}

export default class ReportService {
  static context = createContext<ReportService | null>(null);

  constructor(private url: string, private accessToken: string) {}

  private async request(
    method: string,
    path: string,
    queryParams?: object,
    body?: object
  ) {
    return request(this.accessToken, this.url, method, path, queryParams, body);
  }

  async getReportsForGroup(
    groupId: string,
    from: Date,
    until: Date
  ): Promise<any[]> {
    const response = await this.request(
      'GET',
      `/api/v1/admin/organisations/${groupId}/reports`,
      {
        filter: {
          include: [
            'attempts',
            'event',
            { relation: 'user', scope: { include: 'details' } },
          ],
          where: {
            statusChangedAt: {
              between: [from, until],
            },
          },
        },
      }
    );
    if (!response.ok) throw new Error('Failed to get reports');
    return response.json();
  }
}
