import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Collapse, List } from '@mui/material';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import React, { useContext, useMemo } from 'react';
import authContext from 'react-shared-library/auth/authContext';
import { isUUID } from 'react-shared-library/components/common/uuidRegex';
import signedInUserContext from 'react-shared-library/context/user-context';
import { RtdGroup } from 'react-shared-library/types';

import { getCatchflowDocumentationUrl, getSupportEmail } from './environment';

interface NavigationListProps {
  // If a group is selected, pass it through to open the nested group navigation
  group?: RtdGroup;
}

export default function NavigationList(props: NavigationListProps) {
  const auth = useContext(authContext);
  const signedInUserCtx = useContext(signedInUserContext);

  const showLegacyReports = useMemo(
    () => signedInUserCtx?.showLegacyReports(),
    [signedInUserCtx]
  );
  const showSettings = signedInUserCtx?.isOwner(props.group?.id ?? '');
  const showCatchflow = signedInUserCtx?.hasEntitlement('catchflow');
  const showWorkflows = signedInUserCtx?.hasEntitlement(
    'organisationWorkflowManagement'
  );

  return (
    <React.Fragment>
      <ListSubheader>
        <Typography variant="label">Manage</Typography>
      </ListSubheader>
      <Link href="/groups" underline="none" color="primary">
        <ListItemButton>
          <ListItemText primary="Groups" />
        </ListItemButton>
      </Link>
      <Collapse
        in={!!props.group}
        sx={{ bgcolor: true ? 'rgba(255,255,255, 0.05)' : null }}
      >
        <List component="div" disablePadding>
          <ListSubheader sx={{ bgcolor: 'transparent', pl: 4 }}>
            <Typography sx={{ fontSize: '12px' }} variant="label">
              {!props.group?.name || isUUID(props.group?.name) ? (
                <i>Deckhand Group</i>
              ) : (
                props.group?.name
              )}
            </Typography>
          </ListSubheader>
          <Link
            href={`/groups/${props.group?.id}/members`}
            underline="none"
            color="primary"
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemText primary="Members" />
            </ListItemButton>
          </Link>
          {showWorkflows && (
            <Link
              href={`/groups/${props.group?.id}/workflows`}
              underline="none"
              color="primary"
            >
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Workflows" />
              </ListItemButton>
            </Link>
          )}
          {showLegacyReports && (
            <Link
              href={`/groups/${props.group?.id}/reports`}
              underline="none"
              color="primary"
            >
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Reports" />
              </ListItemButton>
            </Link>
          )}
          {showSettings && (
            <Link
              href={`/groups/${props.group?.id}/settings`}
              underline="none"
              color="primary"
            >
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </Link>
          )}
        </List>
      </Collapse>
      <Divider />
      {showCatchflow && (
        <React.Fragment>
          <ListSubheader>
            <Typography variant="label">Catchflow</Typography>
          </ListSubheader>
          {/* <Link href="/workflows" underline="none" color="primary">
            <ListItemButton>
              <ListItemText primary="Workflows" />
            </ListItemButton>
          </Link> */}
          <a
            href={`mailto:${getSupportEmail()}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <ListItemButton>
              <ListItemText primary="Support" />
              <OpenInNewIcon fontSize="small" />
            </ListItemButton>
          </a>
          <a
            href={getCatchflowDocumentationUrl()}
            target="_blank"
            rel="noreferrer external"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <ListItemButton>
              <ListItemText>Documentation</ListItemText>
              <OpenInNewIcon fontSize="small" />
            </ListItemButton>
          </a>
          <Divider />
        </React.Fragment>
      )}
      <ListSubheader>
        <Typography variant="label">Account</Typography>
      </ListSubheader>
      <Link href="/profile" underline="none" color="primary">
        <ListItemButton>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </Link>
      {/* <Link href="/billing" underline="none" color="primary">
        <ListItemButton>
          <ListItemText primary="Billing" />
        </ListItemButton>
      </Link> */}
      <ListItemButton onClick={auth?.logout}>
        <ListItemText primary="Sign Out" />
      </ListItemButton>
    </React.Fragment>
  );
}
