import { Toolbar } from '@mui/material';
import Container from '@mui/material/Container';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import ErrorAlert from 'react-shared-library/components/common/ErrorAlert';
import GroupService from 'react-shared-library/rtdapi-sdk/group-service';
import { RtdGroup } from 'react-shared-library/types';

import GroupReportContainer from './GroupReportContainer';
import GroupSettingsContainer from './GroupSettingsContainer';
import GroupUserContainer from './GroupUserContainer';
import GroupWorkflowContainer from './GroupWorkflowContainer';

export default function ViewGroupContainer(props: {
  onGroupChange?: (group: RtdGroup | null) => void;
}) {
  const { groupId } = useParams();
  const groupService = useContext(GroupService.context);

  const [group, setGroup] = useState<RtdGroup | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!groupService) return;
    if (!groupId) return;
    groupService
      .getGroup(groupId)
      .then(setGroup)
      .catch((error) => setError(error.message));
    return () => {
      props.onGroupChange?.(null);
    };
    // eslint-disable-next-line
  }, [groupService, groupId]);

  // eslint-disable-next-line
  useEffect(() => props.onGroupChange?.(group), [group]);

  return (
    <Container maxWidth="xl">
      <ErrorAlert>{error}</ErrorAlert>
      <Toolbar />
      <Routes>
        <Route path="members" element={<GroupUserContainer group={group} />} />
        <Route
          path="reports"
          element={<GroupReportContainer group={group} />}
        />
        <Route
          path="workflows"
          element={<GroupWorkflowContainer group={group} />}
        />
        <Route
          path="settings"
          element={
            <GroupSettingsContainer group={group} onGroupChange={setGroup} />
          }
        />
        <Route path="*" element={<Navigate replace to="members" />} />
      </Routes>
    </Container>
  );
}
