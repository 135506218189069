import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react';
import Header from 'react-shared-library/components/common/Header';
import WorkflowBox from 'react-shared-library/components/workflow/WorkflowBox';
import { MOCK_WORKFLOWS } from 'react-shared-library/rtdapi-sdk/mockData';

export default function WorkflowHomeContainer() {
  return (
    <Container maxWidth="lg">
      <Header>Workflows</Header>
      <Box sx={{ display: 'flex', gap: 8 }}>
        <Box
          sx={{
            flex: '75% 1 0',
            display: 'grid',
            columnGap: 2,
            rowGap: 2,
            gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )',
          }}
        >
          <WorkflowBox workflow={MOCK_WORKFLOWS[0]} sx={{ height: '200px' }} />
          <WorkflowBox workflow={MOCK_WORKFLOWS[0]} sx={{ height: '200px' }} />
          <WorkflowBox workflow={MOCK_WORKFLOWS[0]} sx={{ height: '200px' }} />
          <WorkflowBox workflow={MOCK_WORKFLOWS[0]} sx={{ height: '200px' }} />
          <WorkflowBox workflow={MOCK_WORKFLOWS[0]} sx={{ height: '200px' }} />
          <WorkflowBox workflow={MOCK_WORKFLOWS[0]} sx={{ height: '200px' }} />
          <WorkflowBox workflow={MOCK_WORKFLOWS[0]} sx={{ height: '200px' }} />
          <WorkflowBox workflow={MOCK_WORKFLOWS[0]} sx={{ height: '200px' }} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <p>
            These are workflows you have created. To create a new workflow, use
            the&nbsp;
            <a
              href="https://catchflow.io"
              target="_blank"
              rel="noreferrer external"
              style={{ textDecoration: 'none', color: '#2378BD' }}
            >
              Catchflow IDE
              <OpenInNewIcon sx={{ mx: 1 }} fontSize="small" />
            </a>
            and publish directly from the application.
          </p>
        </Box>
      </Box>
    </Container>
  );
}
