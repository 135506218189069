import { createContext } from 'react';
import { IWorkflowService } from '../types';

import { request } from './request';

export default class WorkflowService implements IWorkflowService {
  static context = createContext<IWorkflowService | null>(null);

  constructor(private url: string, private accessToken: string) {}

  private async request(
    method: string,
    path: string,
    queryParams?: object,
    body?: object
  ) {
    return request(this.accessToken, this.url, method, path, queryParams, body);
  }

  /**
   * Fetches all workflows
   *
   * @returns Returns a list of workflows
   */
  async getWorkflows() {
    const response = await this.request('GET', '/api/v1/Workflows');
    if (!response.ok) throw new Error('Failed to get workflows');
    const json = await response.json();
    return json.map((workflow: any) => ({
      ...workflow,
      createdAt: new Date(workflow.createdAt),
    }));
  }

  /**
   * Creates a new workflow
   *
   * @param payload The data to create a new workflow with
   * @returns The newly created workflow
   */
  async createWorkflow(payload: {
    name: string;
    createdAt: string;
    id: string;
    author: string;
    tags: string[];
  }) {
    const response = await this.request(
      'POST',
      '/api/v1/Workflows',
      undefined,
      payload
    );
    if (!response.ok) throw new Error('Failed to create workflow');
    const workflow = await response.json();
    return {
      ...workflow,
      createdAt: new Date(workflow.createdAt),
      priority: 0,
    };
  }

  /**
   * Deletes a workflow by ID
   *
   * @param workflowId The workflow ID to delete
   */
  async deleteWorkflow(workflowId: string) {
    if (!workflowId)
      throw new Error(
        'Workflow ID was not provided attempting to delete workflow'
      );
    const response = await this.request(
      'DELETE',
      `/api/v1/Workflows/${workflowId}`
    );
    if (!response.ok) throw new Error('Failed to delete workflow');
  }
}
