import { LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useEffect, useState } from 'react';
import GroupService from 'react-shared-library/rtdapi-sdk/group-service';
import WorkflowService from 'react-shared-library/rtdapi-sdk/workflow-service';
import { RtdGroup, RtdWorkflow } from 'react-shared-library/types';

import ErrorAlert from 'react-shared-library/components/common/ErrorAlert';
import WorkflowTable from 'react-shared-library/components/workflow/WorkflowTable';

interface AddWorkflowToGroupDialogProps {
  open: boolean;
  group: RtdGroup;
  onClose: () => void;
  onAdded: (workflow: RtdWorkflow) => void;
}

export default function AddWorkflowToGroupDialog(
  props: AddWorkflowToGroupDialogProps
) {
  const workflowService = useContext(WorkflowService.context);
  const groupService = useContext(GroupService.context);
  const [workflows, setWorkflows] = useState<RtdWorkflow[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!workflowService) return;
    workflowService.getWorkflows().then((workflows) => {
      setWorkflows(workflows);
    });
  }, [workflowService]);

  const handleSelectWorkflow = (workflow: RtdWorkflow) => {
    if (!groupService || loading) return;
    setError(null);
    setLoading(true);
    groupService
      .addWorkflowToGroup(props.group.id, workflow.id)
      .then((workflow) => props.onAdded(workflow))
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen>
      <DialogTitle>Add Workflow to Group</DialogTitle>
      <DialogContent>
        <WorkflowTable
          workflows={workflows}
          onSelectWorkflow={handleSelectWorkflow}
        />
      </DialogContent>
      {loading && <LinearProgress />}
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
      <ErrorAlert onClose={() => setError(null)}>{error}</ErrorAlert>
    </Dialog>
  );
}
