import { createContext } from 'react';
import {
  IGroupService,
  RtdEntitlement,
  RtdGroup,
  RtdGroupWithSubgroups,
  RtdReport,
  RtdWorkflowPreference,
} from '../types';

import { request } from './request';

const DEFAULT_PAGINATION = 100;

export const apiOrganisationToGroup = (organisation: any): RtdGroup => {
  return {
    id: organisation.id,
    shortCode: organisation.details?.shortCode,
    name: organisation.details?.name,
    ownerId: organisation.ownerId,
    ownerType: organisation.ownerType,
  };
};

export const apiOrganisationToSubGroup = (
  organisation: any
): RtdGroupWithSubgroups => {
  return {
    id: organisation.id,
    shortCode: organisation.details?.shortCode,
    name: organisation.details?.name,
    ownerId: organisation.ownerId,
    ownerType: organisation.ownerType,
    subgroups: organisation.organisations.map(apiOrganisationToGroup),
  };
};

export default class GroupService implements IGroupService {
  static context = createContext<IGroupService | null>(null);

  constructor(private url: string, private accessToken: string) {}

  private async request(
    method: string,
    path: string,
    queryParams?: object,
    body?: object
  ) {
    return request(this.accessToken, this.url, method, path, queryParams, body);
  }

  /**
   * Gets a list of groups that match a filter
   *
   * @param page The page number to fetch
   * @param where An optional where filter
   * @returns A list of matching groups
   */
  async getGroups(page: number = 0, where: { id?: string; name?: string }) {
    // Name is held on a different model to the main organisation
    // To work around this awkwardness until they are moved, there must be two queries
    if (where.name) {
      const response = await this.request(
        'GET',
        '/api/v1/admin/organisations-details',
        {
          filter: {
            limit: DEFAULT_PAGINATION,
            skip: DEFAULT_PAGINATION * page,
            include: 'organisation',
            where: {
              name: where.name ? { ilike: `%${where.name}%` } : undefined,
            },
          },
        }
      );
      if (!response.ok) throw new Error('Failed to get groups');
      const json = await response.json();
      return json.map((orgDetails: any) => ({
        id: orgDetails.organisation?.id,
        shortCode: orgDetails.shortCode,
        name: orgDetails.name,
      }));
    } else {
      const response = await this.request(
        'GET',
        '/api/v1/admin/organisations',
        {
          filter: {
            limit: DEFAULT_PAGINATION,
            skip: DEFAULT_PAGINATION * page,
            include: 'details',
            where: { id: where.id },
          },
        }
      );
      if (!response.ok) throw new Error('Failed to get groups');
      const json = await response.json();
      return json.map(apiOrganisationToGroup);
    }
  }

  /**
   * Fetches a group by its ID
   *
   * @param groupId The group ID to fetch
   * @returns A group
   */
  async getGroup(groupId: string) {
    const response = await this.request(
      'GET',
      `/api/v1/organisations/${groupId}`,
      {
        filter: {
          include: 'details',
        },
      }
    );
    if (!response.ok) throw new Error('Failed to get group');
    const json = await response.json();
    return apiOrganisationToGroup(json);
  }

  /**
   * Fetches subgroups associated with a group (groups the group owns)
   *
   * @param groupId The group ID to fetch
   * @returns A list of groups
   */
  async getSubGroups(groupId: string) {
    const response = await this.request(
      'GET',
      `/api/v1/admin/organisations/${groupId}/organisations`,
      {
        filter: {
          include: 'details',
        },
      }
    );
    if (!response.ok) throw new Error('Failed to get subgroups');
    const json = await response.json();
    return json.map(apiOrganisationToGroup);
  }

  /**
   * Searches for groups that match a string
   *
   * @param search A search string, either ID or name
   * @returns An array of matching groups
   */
  async searchGroups(search: string) {
    // Name and Short Code are held on a different model to the main organisation
    // To work around this awkwardness until they are moved, there must be two queries
    const detailsResponse = await this.request(
      'GET',
      '/api/v1/admin/organisations-details',
      {
        filter: {
          include: 'organisation',
          limit: 10,
          where: {
            or: [
              { name: { ilike: `%${search}%` } },
              { shortCode: { ilike: `%${search}%` } },
            ],
          },
        },
      }
    );
    const detailsJson = await detailsResponse.json();
    const detailsGroups: RtdGroup[] = detailsJson
      .filter((orgDetails: any) => orgDetails.organisation)
      .map((orgDetails: any) => ({
        id: orgDetails.organisation.id,
        shortCode: orgDetails.shortCode,
        name: orgDetails.name,
      }));

    const response = await this.request('GET', '/api/v1/admin/organisations', {
      filter: {
        include: 'details',
        where: { id: search },
      },
    });
    if (!response.ok) throw new Error('Failed to get groups');
    const json = await response.json();
    const idGroups: RtdGroup[] = json.map(apiOrganisationToGroup);

    const groups = [...idGroups, ...detailsGroups];
    const ids = groups.map((group) => group.id);

    const removingDuplicates = groups.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    return removingDuplicates;
  }

  /**
   * Fetches a count of groups matching a filter
   *
   * @param filters A LoopBack where filter
   * @returns The count of groups that match the query
   */
  async countGroups(filters: { id?: string; name?: string }) {
    if (filters.name) {
      const response = await this.request(
        'GET',
        '/api/v1/admin/organisations-details/count',
        {
          filter: {
            where: {
              name: filters.name ? { ilike: `%${filters.name}%` } : undefined,
            },
          },
        }
      );
      if (!response.ok) throw new Error('Failed to get group count');
      const { count } = await response.json();
      return count;
    } else {
      const response = await this.request(
        'GET',
        '/api/v1/admin/organisations/count',
        {
          filter: {
            where: { id: filters.id },
          },
        }
      );
      if (!response.ok) throw new Error('Failed to get group count');
      const { count } = await response.json();
      return count;
    }
  }

  /**
   * Creates a new group
   *
   * @param payload The details to create the group
   * @returns The newly created group
   */
  async createGroup(payload: { name: string }) {
    const organisationDetailsResponse = await this.request(
      'POST',
      '/api/v1/admin/organisations-details',
      undefined,
      payload
    );
    if (!organisationDetailsResponse.ok)
      throw new Error('Error creating organisation details');
    const detailsId = await organisationDetailsResponse
      .json()
      .then((json) => json.id);

    const organisationResponse = await this.request(
      'POST',
      '/api/v1/admin/organisations',
      undefined,
      { detailsType: 'OrganisationDetailsInternal', detailsId }
    );
    if (!organisationResponse.ok)
      throw new Error('Error creating organisation');
    const id = await organisationResponse.json().then((json) => json.id);
    return { id, name: payload.name };
  }

  /**
   * Creates a new group from a user
   */
  async createOwnGroup(payload: {
    name: string;
    ownerId: string | null;
  }): Promise<RtdGroup> {
    const response = await this.request(
      'POST',
      '/api/v1/organisations',
      undefined,
      payload
    );
    if (!response.ok) throw new Error('Failed to create group');
    return response.json();
  }

  async getUsersForGroup(groupId: string) {
    const response = await this.request(
      'GET',
      `/api/v1/organisations/${groupId}/members`
    );
    if (!response.ok) throw new Error('Failed to get users for group');
    return response.json();
  }

  async addUserToGroup(userId: string, groupId: string) {
    const response = await this.request(
      'POST',
      `/api/v1/OrganisationMembers`,
      undefined,
      {
        userId: userId,
        organisationId: groupId,
      }
    );
    if (!response.ok) throw new Error('Failed to add user to group');

    const response2 = await this.request(
      'POST',
      `/api/v1/Permissions`,
      undefined,
      {
        role: 'ORG_MEMBER',
        modelId: groupId,
        modelName: 'OrganisationInternal',
        userId,
      }
    );
    if (!response2.ok)
      throw new Error('Failed to add permission while adding user to group');
  }

  async removeUserFromGroup(userId: string, groupId: string) {
    if (!userId || !groupId)
      throw new Error('Bad parameters passed to remove user from group');
    const response = await this.request(
      'DELETE',
      `/api/v1/organisations/${groupId}/members/${userId}`
    );
    if (!response.ok) throw new Error('Failed to delete organisation member');
  }

  async getWorkflowsForGroups(groupIds: string[]) {
    let workflows: any[] = await Promise.all(
      groupIds.map((groupId) => this.getWorkflowForGroup(groupId))
    );
    workflows = workflows.reduce(
      (arr, workflows) => [...arr, ...workflows],
      []
    );
    const ids = workflows.map((wf) => wf.id);
    const removingDuplicates = workflows.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    return removingDuplicates;
  }

  async getWorkflowForGroup(groupId: string) {
    const response = await this.request(
      'GET',
      `/api/v1/admin/organisations/${groupId}/workflows`
    );
    if (!response.ok) throw new Error('Failed to get workflows');
    const json = await response.json();
    return json.map((workflow: any) => ({
      ...workflow,
      createdAt: new Date(workflow.createdAt),
    }));
  }

  async addWorkflowToGroup(groupId: string, workflowId: string) {
    const response = await this.request(
      'PUT',
      `/api/v1/admin/organisations/${groupId}/workflows/rel/${workflowId}`
    );
    if (!response.ok) throw new Error('Failed to add workflow');

    // Fetch the added workflow
    const response2 = await this.request(
      'GET',
      `/api/v1/Workflows/${workflowId}`
    );
    if (!response2.ok) throw new Error('Failed to get workflow');
    const json = await response2.json();
    return {
      ...json,
      createdAt: new Date(json.createdAt),
    };
  }

  async removeWorkflowFromGroup(groupId: string, workflowId: string) {
    const response = await this.request(
      'DELETE',
      `/api/v1/admin/organisations/${groupId}/workflows/rel/${workflowId}`
    );
    if (!response.ok) throw new Error('Failed to add workflow');
  }

  async getEntitlements(groupId: string): Promise<RtdEntitlement[]> {
    const response = await this.request(
      'GET',
      `/api/v1/admin/organisations/${groupId}/entitlements`
    );
    if (!response.ok) throw new Error('Failed to get group entitlements');
    const json = await response.json();
    return json.map((entitlement: any) => ({
      ...entitlement,
      expiry: entitlement.expiry ? new Date(entitlement.expiry) : null,
      createdAt: new Date(entitlement.createdAt),
    }));
  }

  async getEntitlementsForGroups(
    groupIds: string[]
  ): Promise<RtdEntitlement[]> {
    const results = await Promise.all(
      groupIds.map((groupId) => this.getEntitlements(groupId))
    );
    return results.reduce((entitlements, e) => [...entitlements, ...e], []);
  }

  async deleteEntitlements(entitlementId: string): Promise<void> {
    if (!entitlementId) throw new Error('ID is required to delete entitlement');
    const response = await this.request(
      'DELETE',
      `/api/v1/Entitlements/${entitlementId}`
    );
    if (!response.ok) throw new Error('Failed to delete entitlement');
  }

  /**
   * Creates a new entitlement for a group
   *
   * @param groupId The ID of the group to attach the entitlement to
   * @param payload The entitlement data
   * @returns The newly created entitlement
   */
  async addEntitlementToGroup(
    groupId: string,
    payload: Partial<RtdEntitlement>
  ): Promise<RtdEntitlement> {
    const response = await this.request(
      'POST',
      '/api/v1/Entitlements',
      undefined,
      {
        resourceId: groupId,
        resourceType: 'OrganisationInternal',
        ...payload,
      }
    );
    if (!response.ok) throw new Error('Error creating entitlement');
    const json = await response.json();
    return {
      ...json,
      expiry: json.expiry ? new Date(json.expiry) : null,
      createdAt: new Date(json.createdAt),
    };
  }

  async getWorkflowPreference(groupId: string) {
    const response = await this.request(
      'GET',
      '/api/v1/WorkflowPreferences/findOne',
      {
        filter: { where: { resourceId: groupId } },
      }
    );
    if (!response.ok) return null;
    const json = await response.json();
    return json as RtdWorkflowPreference;
  }

  async setWorkflowPreference(groupId: string, workflowId?: string) {
    // Delete existing
    await this.request('DELETE', '/api/v1/WorkflowPreferences', {
      where: { resourceId: groupId },
    });

    if (!workflowId) return null;
    const response = await this.request(
      'POST',
      '/api/v1/WorkflowPreferences',
      undefined,
      {
        resourceId: groupId,
        resourceType: 'OrganisationInternal',
        preferences: { preferredWorkflow: workflowId },
      }
    );
    if (!response.ok) throw new Error('Failed to set workflow preference');
    const json = await response.json();
    return json as RtdWorkflowPreference;
  }

  /**
   * Invites an email address to join the group
   *
   * @param email The email to invite
   */
  async inviteUser(
    groupId: string,
    email: string,
    message: string
  ): Promise<void> {
    const response = await this.request(
      'POST',
      `/api/v1/organisations/${groupId}/invite`,
      undefined,
      {
        email,
        message,
      }
    );
    if (!response.ok) throw new Error('Failed to invite user');
  }

  /**
   * The "group admin" (an RTD assigned role) ability to create a new user
   * and assign them to a group. This was formerly called "group manager".
   *
   * @param groupId The ID of the group to create the user within
   * @param email The users email
   * @param password The users password
   * @param name The users name
   */
  async createUser(
    groupId: string,
    email: string,
    password: string,
    name: string
  ) {
    const response = await this.request(
      'POST',
      `/api/v1/organisations/${groupId}/members`,
      undefined,
      {
        email,
        password,
        details: { name },
      }
    );
    if (!response.ok) throw new Error('Failed to create user');
  }

  /**
   *
   * @param groupId The ID of the group to work within
   * @param userId The ID of the user to edit
   * @param email The users new email
   * @param name The users new name
   * @param isManager If the user should be granted management role within the group
   */
  async editUser(
    groupId: string,
    userId: string,
    email: string,
    name: string,
    isManager: boolean
  ): Promise<void> {
    const response = await this.request(
      'PATCH',
      `/api/v1/organisations/${groupId}/members/${userId}`,
      undefined,
      {
        email,
        details: { name },
      }
    );
    if (!response.ok) throw new Error('Failed to update user');

    if (isManager) {
      const response2 = await this.request(
        'PUT',
        `/api/v1/organisations/${groupId}/members/${userId}/roles`,
        undefined,
        [{ role: 'ORG_MEMBER' }, { role: 'ORG_MANAGER' }]
      );
      if (!response2.ok) throw new Error('Failed to update user roles');
    }
  }

  async transferOwnership(
    groupId: string,
    email: string,
    message: string
  ): Promise<void> {
    const response = await this.request(
      'POST',
      `/api/v1/organisations/${groupId}/ownershipInvite`,
      undefined,
      { email, message }
    );
    if (!response.ok) throw new Error('Failed to transfer ownership');
  }

  async renameGroup(groupId: string, name: string): Promise<RtdGroup> {
    const response1 = await this.request(
      'GET',
      `/api/v1/organisations/${groupId}`,
      undefined
    );
    if (!response1.ok) throw new Error('Failed to rename group');
    const { detailsId } = await response1.json();

    const response = await this.request(
      'PATCH',
      `/api/v1/admin/organisations-details/${detailsId}`,
      undefined,
      { name }
    );
    if (!response.ok) throw new Error('Failed to rename group');

    return this.getGroup(groupId);
  }

  async acceptInvitation(
    groupId: string,
    invitationJwt: string
  ): Promise<void> {
    const response = await this.request(
      'GET',
      `/api/v1/organisations/${groupId}/invite/accept`,
      { jwt: invitationJwt }
    );
    if (!response.ok) throw new Error('Failed to accept invitation');
  }

  async acceptOwnershipInvitation(
    groupId: string,
    invitationJwt: string
  ): Promise<void> {
    const response = await this.request(
      'GET',
      `/api/v1/organisations/${groupId}/ownershipInvite/accept`,
      { jwt: invitationJwt }
    );
    if (!response.ok) throw new Error('Failed to accept invitation');
  }

  async changeOwnership(
    id: string,
    ownerId: string,
    ownerType: string
  ): Promise<void> {
    if (ownerType !== 'OrganisationInternal' && ownerType !== 'Member')
      throw new Error(`Unhandled owner type '${ownerType}'`);

    const response = await this.request(
      'PATCH',
      `/api/v1/admin/organisations/${id}`,
      undefined,
      {
        ownerType,
        ownerId,
      }
    );
    if (!response.ok) throw new Error('Failed to change ownership');
  }

  async getReports(
    groupId: string,
    from: Date,
    until: Date
  ): Promise<RtdReport[]> {
    const response = await this.request(
      'GET',
      `/api/v1/admin/organisations/${groupId}/reports`,
      {
        filter: {
          include: [
            'attempts',
            'event',
            { relation: 'user', scope: { include: 'details' } },
          ],
          where: {
            statusChangedAt: {
              between: [from, until],
            },
          },
          order: ['statusChangedAt DESC'],
        },
      }
    );
    if (!response.ok) throw new Error('Failed to get reports');
    return response.json();
  }
}
