import { createContext } from 'react';

import {
  IUserService,
  RtdEntitlement,
  RtdGroupWithSubgroups,
  RtdProfile,
  RtdReport,
  RtdUser,
  RtdWorkflowPreference,
} from '../types';
import { apiOrganisationToSubGroup } from './group-service';
import { request } from './request';

const DEFAULT_PAGINATION = 100;

export default class UserService implements IUserService {
  static context = createContext<IUserService | null>(null);

  constructor(private url: string, private accessToken: string) {}

  private async request(
    method: string,
    path: string,
    queryParams?: object,
    body?: object
  ) {
    return request(this.accessToken, this.url, method, path, queryParams, body);
  }

  /**
   * Gets users, optionally providing a filter
   *
   * @param page The page number
   * @param where LoopBack where filter
   * @returns A list of users
   */
  async getUsers(
    page: number = 0,
    where: { email?: any; id?: any }
  ): Promise<RtdUser[]> {
    const response = await this.request('GET', '/api/v1/Members', {
      filter: {
        limit: DEFAULT_PAGINATION,
        skip: DEFAULT_PAGINATION * page,
        include: 'details',
        where: {
          id: where.id,
          email: where.email ? { ilike: `%${where.email}%` } : undefined,
        },
      },
    });
    if (!response.ok) throw new Error('Failed to get users');
    const json = await response.json();
    return json;
  }

  /**
   * Search for users using a loose search filter
   *
   * @param search A string to search for. May include email, ID, or identities contents like FishServe ID
   * @returns A list of users
   */
  async searchUsers(search: string): Promise<RtdUser[]> {
    const users = await Promise.all([
      this.searchUsersByDetails(search),
      this.searchUsersByMember(search),
      this.searchUsersByIdentities(search),
    ]).then(([a, b, c]) => [...a, ...b, ...c]);

    const ids = users.map((user) => user.id);

    const removingDuplicates = users.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    return removingDuplicates;
  }

  /**
   * Fetches a single userId
   * @param userId The user ID to find
   * @returns A user
   */
  async getUser(userId: string): Promise<RtdUser> {
    const response = await this.request('GET', `/api/v1/Members/${userId}`, {
      filter: { include: 'details' },
    });
    if (!response.ok) throw new Error('Failed to get user');
    const json = await response.json();
    return json;
  }

  /**
   * Returns the count of users that match the filter, useful for pagination
   *
   * @param filters A where filter to apply to the count
   */
  async countUsers(filters: { email?: any; id?: any }): Promise<number> {
    const response = await this.request('GET', '/api/v1/Members/count', {
      filter: { where: filters },
    });
    if (!response.ok) throw new Error('Failed to get count of users');
    const { count } = await response.json();
    return count;
  }

  /**
   * Get a list of groups for a given user. This call will also include subgroups
   *
   * @param userId The ID of the user to fetch groups for
   * @returns An array of groups
   */
  async getGroupsForUser(userId: string): Promise<RtdGroupWithSubgroups[]> {
    await this.request(
      'GET',
      `/api/v1/organisations/35bd8c6a-9501-470d-944c-a3de59a4e318/members`
    );
    const response = await this.request(
      'GET',
      `/api/v1/Members/${userId}/memberships`,
      {
        filter: {
          include: [
            { relation: 'details' },
            // Include groups each group owns
            { relation: 'organisations', scope: { include: 'details' } },
          ],
        },
      }
    );
    if (!response.ok) throw new Error('Failed to get users groups');
    const json = await response.json();
    return json.map(apiOrganisationToSubGroup);
  }

  /**
   * Creates a new entitlement for a user
   *
   * @param userId The ID of the user to attach the entitlement to
   * @param payload The entitlement data
   * @returns The newly created entitlement
   */
  async addEntitlementToUser(
    userId: string,
    payload: Partial<RtdEntitlement>
  ): Promise<RtdEntitlement> {
    const response = await this.request(
      'POST',
      '/api/v1/Entitlements',
      undefined,
      {
        resourceId: userId,
        resourceType: 'Member',
        ...payload,
      }
    );
    if (!response.ok) throw new Error('Error creating entitlement');
    const json = await response.json();
    return {
      ...json,
      expiry: json.expiry ? new Date(json.expiry) : null,
      createdAt: new Date(json.createdAt),
    };
  }

  async getEntitlements(userId: string): Promise<RtdEntitlement[]> {
    const response = await this.request(
      'GET',
      `/api/v1/Members/${userId}/entitlements`
    );
    if (!response.ok) throw new Error('Failed to get users entitlements');
    const json = await response.json();
    return json.map((entitlement: any) => ({
      ...entitlement,
      expiry: entitlement.expiry ? new Date(entitlement.expiry) : null,
      createdAt: new Date(entitlement.createdAt),
    }));
  }

  async deleteEntitlements(entitlementId: string): Promise<void> {
    if (!entitlementId) throw new Error('ID is required to delete entitlement');
    const response = await this.request(
      'DELETE',
      `/api/v1/Entitlements/${entitlementId}`
    );
    if (!response.ok) throw new Error('Failed to delete entitlement');
  }

  async updateEmail(userId: string, email: string): Promise<void> {
    const response = await this.request(
      'PATCH',
      `/api/v1/Members/${userId}`,
      undefined,
      { email }
    );
    if (!response.ok) throw new Error('Failed to update user email');
  }

  async updatePhoneNumber(userId: string, phoneNumber: string): Promise<void> {
    const response = await this.request(
      'PATCH',
      `/api/v1/Members/${userId}`,
      undefined,
      { phoneNumber }
    );
    if (!response.ok) throw new Error('Failed to update user phone');
  }

  async updateName(userId: string, name: string): Promise<void> {
    const existRequest = await this.request(
      'GET',
      `/api/v1/Members/${userId}/details`
    );
    // If the details exist, use a PATCH request. Otherwise, POST a new instance
    const response = await this.request(
      existRequest.ok ? 'PUT' : 'POST',
      `/api/v1/Members/${userId}/details`,
      undefined,
      { name }
    );
    if (!response.ok) throw new Error('Failed to update user name');
  }

  async getWorkflowPreferences(
    groupIds: string[],
    userId: string
  ): Promise<RtdWorkflowPreference[]> {
    const response = await this.request('GET', '/api/v1/WorkflowPreferences', {
      filter: { where: { resourceId: { inq: [...groupIds, userId] } } },
    });
    if (!response.ok) throw new Error('Failed to get workflow preference');
    const json = await response.json();
    return json as RtdWorkflowPreference[];
  }

  async setWorkflowPreference(userId: string, workflowId?: string) {
    // Delete existing
    await this.request('DELETE', '/api/v1/WorkflowPreferences', {
      where: { resourceId: userId },
    });

    if (!workflowId) return null;
    const response = await this.request(
      'POST',
      '/api/v1/WorkflowPreferences',
      undefined,
      {
        resourceId: userId,
        resourceType: 'Member',
        preferences: { preferredWorkflow: workflowId },
      }
    );
    if (!response.ok) throw new Error('Failed to set workflow preference');
    const json = await response.json();
    return json as RtdWorkflowPreference;
  }

  private async searchUsersByDetails(search: string): Promise<RtdUser[]> {
    const response = await this.request('GET', '/api/v1/MemberDetails', {
      filter: {
        limit: 10,
        where: {
          name: { ilike: `%${search}%` },
        },
        include: { relation: 'member' },
      },
    });
    if (!response.ok) throw new Error('Failed to search users');
    const json = await response.json();
    return json.map((response: any) => {
      const { member, ...details } = response;
      return { ...member, details };
    });
  }

  private async searchUsersByMember(search: string): Promise<RtdUser[]> {
    const response = await this.request('GET', '/api/v1/Members', {
      filter: {
        where: {
          or: [
            {
              email: { ilike: `%${search}%` },
            },
            {
              id: search,
            },
          ],
        },
        include: 'details',
      },
    });
    if (!response.ok) throw new Error('Failed to search users');
    return response.json();
  }

  private async searchUsersByIdentities(search: string): Promise<RtdUser[]> {
    const response = await this.request('GET', '/api/v1/UserIdentities', {
      filter: {
        limit: 10,
        where: {
          profile: { ilike: `%${search}%` },
        },
        include: { relation: 'user', scope: { include: 'details' } },
      },
    });
    if (!response.ok) throw new Error('Failed to search users');
    const json = await response.json();
    return json.map((identity: any) => identity.user);
  }

  async getProfile(): Promise<RtdProfile> {
    const response = await this.request('GET', '/api/v1/profile');
    if (!response.ok) throw new Error('Failed to get profile');
    const profile = await response.json();
    return { ...profile, details: { name: profile.name } };
  }

  async getReports(
    userId: string,
    from: Date,
    until: Date
  ): Promise<RtdReport[]> {
    const response = await this.request(
      'GET',
      `/api/v1/Members/${userId}/reports`,
      {
        filter: {
          include: [
            'attempts',
            'event',
            { relation: 'user', scope: { include: 'details' } },
          ],
          where: {
            statusChangedAt: {
              between: [from, until],
            },
          },
          order: ['statusChangedAt DESC'],
        },
      }
    );
    if (!response.ok) throw new Error('Failed to get reports');
    return response.json();
  }
}
