import Typography from '@mui/material/Typography';
import React from 'react';
import { WorkflowVersionState } from 'react-shared-library/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

interface VersionStateProps {
  state: WorkflowVersionState;
}

function EarlyAccess() {
  return (
    <React.Fragment>
      <PendingIcon
        color="secondary"
        fontSize="small"
        sx={{ verticalAlign: 'middle', mr: 1 }}
      />
      <Typography variant="label">Early Access</Typography>
    </React.Fragment>
  );
}
function Live() {
  return (
    <React.Fragment>
      <CheckCircleIcon
        color="success"
        fontSize="small"
        sx={{ verticalAlign: 'middle', mr: 1 }}
      />
      <Typography variant="label">Live</Typography>
    </React.Fragment>
  );
}

export default function VersionState(props: VersionStateProps) {
  switch (props.state) {
    case 'EARLY_ACCESS':
      return <EarlyAccess />;
    case 'LIVE':
      return <Live />;
    default:
      return <p>Unknown state: {props.state}</p>;
  }
}
