import { Box, Chip, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useState } from 'react';

function CustomDatePicker(props: {
  onChange: (from: Date, until: Date) => void;
}) {
  const [from, setFrom] = useState(dayjs().startOf('day').toDate());
  const [until, setUntil] = useState(dayjs().endOf('day').toDate());

  const handleChange = () => {
    props.onChange(from, until);
  };

  return (
    <React.Fragment>
      <DesktopDatePicker
        value={from}
        onChange={(date) => {
          if (!date) return;
          setFrom(dayjs(date.valueOf()).startOf('day').toDate());
          handleChange();
        }}
        label="From"
        renderInput={(params) => <TextField {...params} />}
      />
      <DesktopDatePicker
        value={until}
        onChange={(date) => {
          if (!date) return;
          setUntil(dayjs(date.valueOf()).endOf('day').toDate());
          handleChange();
        }}
        label="Until"
        renderInput={(params) => <TextField {...params} />}
      />
    </React.Fragment>
  );
}

export default function ReportDateFilter(props: {
  onUpdate: (from: Date, until: Date) => void;
}) {
  const [range, setRange] = useState('today');

  const presets: any = {
    today: [dayjs().startOf('day'), dayjs().endOf('day')],
    pastWeek: [
      dayjs().subtract(1, 'week').startOf('day'),
      dayjs().endOf('day'),
    ],
    pastMonth: [
      dayjs().subtract(1, 'month').startOf('day'),
      dayjs().endOf('day'),
    ],
    past90: [dayjs().subtract(90, 'day').startOf('day'), dayjs().endOf('day')],
  };

  const handleRange = (range: string) => {
    setRange(range);
    const rangeStart = presets[range][0];
    const rangeEnd = presets[range][1];

    const startDay = dayjs(rangeStart).startOf('day');
    const endDay = dayjs(rangeEnd).endOf('day');

    props.onUpdate(startDay.toDate(), endDay.toDate());
  };
  const handleCustom = (from: Date, until: Date) => {
    setRange('custom');
    props.onUpdate(from, until);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Chip
          label="Today"
          onClick={() => handleRange('today')}
          color={range === 'today' ? 'primary' : 'default'}
        />
        <Chip
          label="Past Week"
          onClick={() => handleRange('pastWeek')}
          color={range === 'pastWeek' ? 'primary' : 'default'}
        />
        <Chip
          label="Past Month"
          onClick={() => handleRange('pastMonth')}
          color={range === 'pastMonth' ? 'primary' : 'default'}
        />
        <Chip
          label="Past 90 Days"
          onClick={() => handleRange('past90')}
          color={range === 'past90' ? 'primary' : 'default'}
        />
        <Chip
          label="Custom"
          onClick={() => setRange('custom')}
          color={range === 'custom' ? 'primary' : 'default'}
        />
        {/* Custom date filters */}
        {range === 'custom' && <CustomDatePicker onChange={handleCustom} />}
      </Box>
    </React.Fragment>
  );
}
