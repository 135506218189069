import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

import { getFirebaseConfig } from './environment';

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const init = () => {
  // Initialize Firebase
  const config = getFirebaseConfig();
  if (config) {
    const app = initializeApp(config);
    // Initialize Analytics and get a reference to the service
    getAnalytics(app);
  }
};
export default init;
