export const request = async (
  accessToken: string,
  urlString: string,
  method: string,
  path: string,
  queryParams?: object,
  body?: object
) => {
  const url = new URL(urlString + path);
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.append(
        key,
        typeof value === 'object' ? JSON.stringify(value) : value
      );
    });
  }
  const headers = new Headers({
    Authorization: accessToken,
    'Content-Type': 'application/json',
  });
  return fetch(url.toString(), {
    headers,
    method,
    body: body ? JSON.stringify(body) : undefined,
  });
};
