import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

import RenderedValue from '../common/RenderedValue';

interface ReportTableProps {
  reports: any[] | null;
  onSelectReport?: (report: any) => any;
  onSelectUser?: (user: any) => any;
}

export default function ReportTable(props: ReportTableProps) {
  if (!props.reports) {
    return (
      <React.Fragment>
        {Array.from({ length: 10 }, (_, i) => (
          <Skeleton key={i} height={60} />
        ))}
      </React.Fragment>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Status Changed</TableCell>
          <TableCell>Report Name</TableCell>
          <TableCell>User</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.reports.map((report) => (
          <TableRow
            key={report.id}
            hover
            role="button"
            onClick={(event) => {
              if (!window.getSelection()?.isCollapsed) return;
              props.onSelectReport?.(report);
            }}
          >
            <TableCell>
              <RenderedValue>
                {dayjs(report.statusChangedAt).format('L LT')}
              </RenderedValue>
            </TableCell>
            <TableCell>
              <RenderedValue>{report.type}</RenderedValue>
            </TableCell>
            <TableCell>
              {props.onSelectUser ? (
                <div
                  className="App-link"
                  onClick={(event) => {
                    props.onSelectUser?.(report.user);
                    event.stopPropagation();
                  }}
                >
                  {report.user?.details?.name}
                </div>
              ) : (
                <RenderedValue>{report.user?.details?.name}</RenderedValue>
              )}
            </TableCell>
            <TableCell>
              {report.status === 'REPORT_COMPLETE' ? (
                <CheckCircleOutlineIcon color="success" />
              ) : report.status === 'REPORT_INCOMPLETE' ? (
                <RadioButtonUncheckedIcon />
              ) : (
                <ErrorOutlineIcon color="error" />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
