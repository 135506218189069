import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';

import InfoAlert from '../common/InfoAlert';
import InformationLabel from '../common/InformationLabel';
import Attempt from './Attempt';

interface ReportViewDialogProps {
  open: boolean;
  report: any;
  onClose?: () => void;
}

export default function ReportViewDialog(props: ReportViewDialogProps) {
  const { report, open } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showRaw, setShowRaw] = useState(false);

  const handleCopyData = () => {
    setSnackbarOpen(true);
    window.navigator.clipboard.writeText(JSON.stringify(props.report.event));
  };

  return (
    <Dialog open={open} fullScreen>
      <InfoAlert onClose={() => setSnackbarOpen(false)}>
        {snackbarOpen ? 'Copied!' : null}
      </InfoAlert>
      <DialogTitle>View Report</DialogTitle>
      <DialogContent>
        <InformationLabel label="Type">{report.type}</InformationLabel>
        <InformationLabel label="Status">{report.status}</InformationLabel>
        <InformationLabel label="User">
          {report.user?.details?.name}
        </InformationLabel>
        <InformationLabel label="Last Updated">
          {dayjs(report.statusChangedAt).format('LLLL')}
        </InformationLabel>
        <Button variant="outlined" onClick={handleCopyData}>
          Copy
        </Button>
        <Button
          sx={{ ml: 1 }}
          variant="outlined"
          onClick={() => setShowRaw(!showRaw)}
        >
          {showRaw ? 'Hide' : 'Show'}
        </Button>
        {showRaw && (
          <small>
            <pre>{JSON.stringify(report.event, null, 2)}</pre>
          </small>
        )}
        {!!report.attempts.length && (
          <Typography sx={{ mt: 2 }} variant="h5">
            Timeline
          </Typography>
        )}
        {report.attempts.map((attempt: any, i: number) => (
          <Attempt attempt={attempt} key={i}></Attempt>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose?.()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
