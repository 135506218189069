import { Alert, Snackbar } from '@mui/material';

interface InfoAlertProps {
  children: React.ReactNode;
  onClose: () => void;
}

export default function InfoAlert(props: InfoAlertProps) {
  return (
    <Snackbar
      open={!!props.children}
      autoHideDuration={2000}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity="info">{props.children}</Alert>
    </Snackbar>
  );
}
