import './App.css';

import { LinkProps } from '@mui/material/Link';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to'];
    external?: boolean;
  }
>((props, ref) => {
  const { href, external, ...other } = props;
  // Map href (MUI) -> to (react-router)
  if (external)
    return (
      <a target="_blank" rel="noreferrer" href={href.toString()}>
        {props.children}
      </a>
    );
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

// MUI Themeing

declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

const customization: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiDrawer: {
      styleOverrides: {},
    },
  },
  typography: {
    h1: { fontWeight: 200, marginBottom: '12px', fontSize: '2.5rem' },
    h2: { fontWeight: 200, marginBottom: '12px', fontSize: '1.8rem' },
    h3: { fontWeight: 200, marginBottom: '12px', fontSize: '1.6rem' },
    h4: { fontWeight: 200, marginBottom: '12px', fontSize: '1.5rem' },
    h5: { fontWeight: 200, marginBottom: '12px', fontSize: '1.4rem' },
    h6: { fontWeight: 200, marginBottom: '12px', fontSize: '1.1rem' },
    label: {
      color: 'gray',
      textTransform: 'uppercase',
      fontSize: 'smaller',
      lineHeight: '1.5rem',
      letterSpacing: '0.07rem',
    },
  },
};

export const theme = createTheme(customization);

export const darkTheme = createTheme({
  ...customization,
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
    background: {
      paper: '#2378BD',
      default: '#2378BD',
    },
  },
  typography: {
    ...customization.typography,
    label: {
      ...(customization as any).typography.label,
      color: '#96bad8',
    },
  },
});
