import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { RtdGroup } from 'react-shared-library/types';
import emailRegex from '../common/emailRegex';

interface TransferOwnershipDialogProps {
  group: RtdGroup;
  open: boolean;
  onInvite: (email: string, message: string) => void;
  onCancel?: () => void;
}

export default function TransferOwnershipDialog(
  props: TransferOwnershipDialogProps
) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(
    `You have been invited to become the owner of ${props.group.name}`
  );

  const valid = email.match(emailRegex) && message.length;

  return (
    <Dialog open={props.open}>
      <DialogTitle>Transfer Ownership</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Email"
          fullWidth
          value={email}
          type="email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          margin="dense"
          label="Invitation Message"
          fullWidth
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button
          onClick={() => props.onInvite(email, message)}
          variant="contained"
          disabled={!valid}
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
