import { Box, Button, Container, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import authContext from 'react-shared-library/auth/authContext';
import ErrorAlert from 'react-shared-library/components/common/ErrorAlert';
import Header from 'react-shared-library/components/common/Header';
import signedInUserContext from 'react-shared-library/context/user-context';
import UserService from 'react-shared-library/rtdapi-sdk/user-service';

export default function ViewProfileContainer() {
  const auth = useContext(authContext);
  const userService = useContext(UserService.context);
  const signedInUserCtx = useContext(signedInUserContext);

  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);

  useEffect(() => {
    if (!signedInUserCtx) return;
    setName(signedInUserCtx.user.details?.name ?? null);
  }, [signedInUserCtx]);

  const handleChangeName = () => {
    if (!userService) return;
    if (!name || !auth?.credentials?.userId) return;
    userService
      .updateName(auth.credentials.userId, name)
      .catch((error) => setError(error.message));
  };

  return (
    <Container>
      <Header>Profile</Header>
      <ErrorAlert>{error}</ErrorAlert>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <TextField
          label="Display Name"
          value={name || ''}
          onChange={(event) => setName(event.target.value)}
        />
        <Button variant="contained" onClick={handleChangeName}>
          Save
        </Button>
      </Box>
    </Container>
  );
}
