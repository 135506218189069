import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { RtdUserWithPermissions } from 'react-shared-library/types';

interface GroupUserTableProps {
  users: RtdUserWithPermissions[] | null;
  // The logged in users ID, to hide from management actions
  showChips?: boolean;
  ownUserId?: string;
  onRemoveUser?: (user: RtdUserWithPermissions) => any;
  onEditUser?: (user: RtdUserWithPermissions) => any;
}

export default function GroupUserTable(props: GroupUserTableProps) {
  if (!props.users) {
    return (
      <React.Fragment>
        {Array.from({ length: 10 }, (_, i) => (
          <Skeleton key={i} height={60} />
        ))}
      </React.Fragment>
    );
  }

  const userIsManager = (user: RtdUserWithPermissions) =>
    !!user.permissions.find((p) => p.role === 'ORG_MANAGER');

  return (
    <List>
      {props.users?.map((user) => (
        <ListItem
          key={user.id}
          secondaryAction={
            <Box>
              {props.onEditUser && props.ownUserId !== user.id && (
                <IconButton onClick={() => props.onEditUser?.(user)}>
                  <EditIcon />
                </IconButton>
              )}
              {/*
                Protecting users from themselves for now - should not be able to remove your
                own user from the group! Maybe in the future
              */}
              {props.onRemoveUser && props.ownUserId !== user.id && (
                <IconButton onClick={() => props.onRemoveUser?.(user)}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          }
        >
          <ListItemText
            primary={
              <Box>
                <Box sx={{ mr: 1, display: 'inline-block' }}>
                  {user.details?.name ?? <i>Deckhand User</i>}
                </Box>
                {userIsManager(user) && <Chip sx={{height: '24px'}} label="Manager" />}
              </Box>
            }
            secondary={user.email}
          />
        </ListItem>
      ))}
    </List>
  );
}
