import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import Container from '@mui/material/Container';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authContext from 'react-shared-library/auth/authContext';
import ErrorAlert from 'react-shared-library/components/common/ErrorAlert';
import Header from 'react-shared-library/components/common/Header';
import SubGroupTable from 'react-shared-library/components/group/SubGroupTable';
import GroupService from 'react-shared-library/rtdapi-sdk/group-service';
import UserService from 'react-shared-library/rtdapi-sdk/user-service';
import { RtdGroupWithSubgroups } from 'react-shared-library/types';

import CreateGroupDialog from './CreateGroupDialog';

export default function GroupHomeContainer() {
  const userService = useContext(UserService.context);
  const groupService = useContext(GroupService.context);
  const auth = useContext(authContext);
  const navigate = useNavigate();
  const [groups, setGroups] = useState<null | RtdGroupWithSubgroups[]>(null);
  const [error, setError] = useState<null | string>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [showNested, setShowNested] = useState(true);

  useEffect(() => {
    if (!userService) return;
    if (!auth?.credentials?.userId) return;
    userService
      .getGroupsForUser(auth.credentials.userId)
      .then(setGroups)
      .catch((error) => setError(error.message));
  }, [userService, auth]);

  const handleCreateGroup = (name: string, ownerId: string | null) => {
    if (!groupService || !userService) return;
    if (!auth?.credentials?.userId) return;
    groupService
      .createOwnGroup({ name, ownerId })
      .then(() =>
        userService.getGroupsForUser(auth!.credentials!.userId).then(setGroups)
      )
      .catch((error) => setError(error.message))
      .finally(() => setCreateDialogOpen(false));
  };

  const hasSubgroups = useMemo(
    () => !!groups?.find((g) => !!g.subgroups.length),
    [groups]
  );

  return (
    <Container maxWidth="lg">
      <ErrorAlert>{error}</ErrorAlert>
      {createDialogOpen && groups && (
        <CreateGroupDialog
          groups={groups}
          onCreate={handleCreateGroup}
          onCancel={() => setCreateDialogOpen(false)}
        />
      )}
      <Header>Groups</Header>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
        }}
      >
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setCreateDialogOpen(true)}
        >
          Create
        </Button>
        {hasSubgroups && (
          <FormControlLabel
            label="Show Nested?"
            control={
              <Checkbox
                checked={showNested}
                onChange={(event) => setShowNested(event.target.checked)}
              />
            }
          />
        )}
      </Box>
      <SubGroupTable
        showNested={showNested}
        groups={groups}
        onSelectGroup={(group) => navigate(`/groups/${group.id}`)}
      />
    </Container>
  );
}
