import {
  MOCK_ENTITLEMENTS,
  MOCK_USERS,
} from 'react-shared-library/rtdapi-sdk/mockData';
import {
  IUserService,
  RtdEntitlement,
  RtdReport,
  RtdUser,
} from 'react-shared-library/types';

export default class MockUserService implements IUserService {
  async getUsers(
    page: number = 0,
    where: { email?: any; id?: any }
  ): Promise<RtdUser[]> {
    return MOCK_USERS;
  }

  async searchUsers(search: string): Promise<RtdUser[]> {
    return MOCK_USERS;
  }

  async getUser(userId: string): Promise<RtdUser> {
    return MOCK_USERS[0];
  }

  async countUsers(filters: { email?: any; id?: any }): Promise<number> {
    return MOCK_USERS.length;
  }

  async getGroupsForUser(userId: string) {
    return [];
  }

  async getEntitlements(userId: string): Promise<RtdEntitlement[]> {
    return MOCK_ENTITLEMENTS;
  }

  async addEntitlementToUser(userId: string, payload: Partial<RtdEntitlement>) {
    return { ...payload, id: 'NEWLY_CREATED' } as any;
  }

  async deleteEntitlements(entitlementId: string) {}

  async updateEmail(userId: string, email: string) {}

  async updateName(userId: string, name: string) {}

  async updatePhoneNumber(userId: string, phoneNumber: string): Promise<void> {}

  async getWorkflowPreferences(groupIds: string[], userId: string) {
    return [];
  }

  async setWorkflowPreference(userId: string, workflowId?: string) {
    return null;
  }

  async getProfile() {
    return { ...MOCK_USERS[0], entitlements: [] };
  }

  async getReports(
    userId: string,
    from: Date,
    until: Date
  ): Promise<RtdReport[]> {
    return [];
  }
}
