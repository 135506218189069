import { useState } from 'react';
import { AuthContext } from '../types';

export default function useProvideAuth(): AuthContext {
  const persistedUserId = localStorage.getItem('userId');
  const persistedAccessToken = localStorage.getItem('accessToken');

  let persistedCredentials = null;
  if (persistedUserId && persistedAccessToken) {
    persistedCredentials = {
      userId: persistedUserId,
      accessToken: persistedAccessToken,
    };
  }

  const [credentials, setCredentials] = useState<null | {
    userId: string;
    accessToken: string;
  }>(persistedCredentials);

  const login = (userId: string, accessToken: string) => {
    setCredentials({ userId, accessToken });
    localStorage.setItem('userId', userId);
    localStorage.setItem('accessToken', accessToken);
  };

  const logout = () => {
    setCredentials(null);
    localStorage.removeItem('userId');
    localStorage.removeItem('accessToken');
  };

  return {
    credentials,
    login,
    logout,
  };
}
