import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';

import InfoAlert from './InfoAlert';
import RenderedValue from './RenderedValue';

interface RenderedValueProps {
  children?: React.ReactNode;
  // By default the component will copy the text content of the children, but this value prop can be used
  // to override and set the copied value upon click.
  value?: string;
  // Props to place at the end of the content inline with the copy button, for example edit buttons
  endAdornments?: React.ReactNode;
}

// Renders the children, with a copy button suffix that will copy the textual value to clipboard
export default function CopyableValue(props: RenderedValueProps) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!props.children) return;
    const strValue = props.children.toString();
    // Copy the contents to clipboard
    window.navigator.clipboard.writeText(props.value ?? strValue);
    // Display a snackbar to visually confirm
    setSnackbarOpen(true);
    // Prevent a clickthrough to the parent element (the particular adding use case being the <GroupTable> record)
    event?.stopPropagation();
  };

  if (!props.children)
    return (
      <RenderedValue endAdornments={props.endAdornments}>
        {props.children}
      </RenderedValue>
    );
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <InfoAlert onClose={() => setSnackbarOpen(false)}>
        {snackbarOpen ? 'Copied!' : null}
      </InfoAlert>
      <RenderedValue endAdornments={props.endAdornments}>
        {props.children}
      </RenderedValue>
      <Box sx={{ p: 0 }}>
        <IconButton sx={{ p: 0 }} size="small" onClick={handleCopy}>
          <ContentCopyIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
