import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { RtdGroup } from 'react-shared-library/types';

interface CreateGroupDialogProps {
  groups: RtdGroup[];
  onCreate: (name: string, ownerId: string | null) => void;
  onCancel: () => void;
}

export default function CreateGroupDialog(props: CreateGroupDialogProps) {
  const [name, setName] = useState('');
  const [owner, setOwner] = useState('Me');

  return (
    <Dialog open>
      <DialogTitle>Create Group</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="group-owner-label">Group Owner</InputLabel>
          <Select
            labelId="group-owner-label"
            label="Group Owner"
            value={owner}
            onChange={(event: any) => setOwner(event.target.value)}
          >
            <MenuItem value="Me">
              <i>Me</i>
            </MenuItem>
            {props.groups.map((group, i) => (
              <MenuItem key={i} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!name}
          onClick={() => {
            props.onCreate(name, owner === 'Me' ? null : owner);
          }}
          variant="contained"
        >
          Create
        </Button>
        <Button onClick={() => props.onCancel()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
