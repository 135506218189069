import CircleIcon from '@mui/icons-material/Circle';
import CopyIcon from '@mui/icons-material/CopyAll';
import { Box, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';

import InfoAlert from '../common/InfoAlert';

export default function Attempt(props: { attempt: any }) {
  const event: any = props.attempt;

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  let messages: any[] = [];
  if (event.payload.messages) {
    messages = Object.entries(event.payload.messages);
  }

  const handleCopyData = () => {
    setSnackbarOpen(true);
    window.navigator.clipboard.writeText(JSON.stringify(event));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <InfoAlert onClose={() => setSnackbarOpen(false)}>
        {snackbarOpen ? 'Copied!' : null}
      </InfoAlert>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ p: 2 }}>
          <CircleIcon
            color={
              event.eventTypeId === 'REPORT_SUCCESS'
                ? 'primary'
                : event.eventTypeId === 'REPORT_FAILURE'
                ? 'warning'
                : 'info'
            }
          />
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box>
            {event.eventTypeId === 'REPORT_SUCCESS' ? 'Success' : 'Failure'}
          </Box>
          <Box>{dayjs(event.createdAt).format('LLLL')}</Box>
        </Box>
        <Box>
          <IconButton onClick={handleCopyData}>
            <CopyIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ borderLeft: 'solid 1px grey', ml: 3, mt: 2, pl: 2 }}>
        {messages.map((messageObj: any[], i: number) => (
          <Box key={i}>
            <Typography variant="h6" sx={{ mb: 0 }}>
              {messageObj[0]}
            </Typography>
            {messageObj[1].map((message: string, j: number) => (
              <p key={j}>{message}</p>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
