export const getFirebaseConfig = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'prod':
      return {
        apiKey: 'AIzaSyBJ7owfkKTqiboWxTb5FimpLOYkoQ_xs_A',
        authDomain: 'partner-portal-d2457.firebaseapp.com',
        projectId: 'partner-portal-d2457',
        storageBucket: 'partner-portal-d2457.appspot.com',
        messagingSenderId: '876609766509',
        appId: '1:876609766509:web:96c01a86a5bb9bae916b64',
        measurementId: 'G-6NHPSPS079',
      };
    case 'stage':
      return {
        apiKey: 'AIzaSyBJ7owfkKTqiboWxTb5FimpLOYkoQ_xs_A',
        authDomain: 'partner-portal-d2457.firebaseapp.com',
        projectId: 'partner-portal-d2457',
        storageBucket: 'partner-portal-d2457.appspot.com',
        messagingSenderId: '876609766509',
        appId: '1:876609766509:web:4ccc6eda89ad6926916b64',
        measurementId: 'G-12MQD4EXBN',
      };
    case 'test':
      return {
        apiKey: 'AIzaSyBJ7owfkKTqiboWxTb5FimpLOYkoQ_xs_A',
        authDomain: 'partner-portal-d2457.firebaseapp.com',
        projectId: 'partner-portal-d2457',
        storageBucket: 'partner-portal-d2457.appspot.com',
        messagingSenderId: '876609766509',
        appId: '1:876609766509:web:71bf1e1b1b7d2e5a916b64',
        measurementId: 'G-509H8CHM6Z',
      };
    default:
      return null;
  }
};

export const getAuthUrl = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'prod':
      return 'https://auth.mydeckhand.com?options=["realtimedata"]';
    case 'stage':
      return 'https://auth-stage.mydeckhand.com?options=["realtimedata"]';
    case 'test':
      return 'https://auth-test.mydeckhand.com?options=["realtimedata"]';
    default:
      return 'https://auth-test.mydeckhand.com?options=["realtimedata"]';
  }
};

export const getRtdApiUrl = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'prod':
      return 'https://rtdnfapi-prod.rtd.systems';
    case 'stage':
      return 'https://rtdnfapi-stage.rtd.systems';
    case 'test':
      return 'https://rtdnfapi-uat.rtd.systems';
    default:
      return 'https://rtdnfapi-uat.rtd.systems';
  }
};

export const getWorkflowApiUrl = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'prod':
      return 'https://workflowapi-prod.rtd.systems';
    case 'stage':
      return 'https://workflowapi-stage.rtd.systems';
    case 'test':
      return 'https://workflowapi-uat.rtd.systems';
    default:
      return 'https://workflowapi-uat.rtd.systems';
  }
};

export const getCatchflowDocumentationUrl = () => 'https://catchflow.io';

export const getSupportEmail = () => 'support@deckhandlogbook.com';
