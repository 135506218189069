import CloseIcon from '@mui/icons-material/Close';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Box, BoxProps, IconButton, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { RtdWorkflow } from 'react-shared-library/types';

import InformationLabel from '../common/InformationLabel';

interface WorkflowBoxProps {
  workflow: RtdWorkflow;
  onRemove?: (workflow: RtdWorkflow) => void;
}

export default function WorkflowBox(
  props: WorkflowBoxProps & { sx?: BoxProps['sx'] }
) {
  return (
    <Box sx={props.sx}>
      <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ width: '100%', backgroundColor: '#eee', p: 1 }}>
          <Typography
            variant="label"
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 1,
            }}
          >
            {props.workflow.id}
            {props.onRemove && (
              <IconButton onClick={() => props.onRemove?.(props.workflow)}>
                <CloseIcon />
              </IconButton>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <WidgetsIcon
            color="primary"
            sx={{ position: 'absolute', top: 10, right: 10, opacity: 0.1 }}
            fontSize="large"
          />
          <Typography variant="h6">{props.workflow.name}</Typography>
          <InformationLabel label="ID">{props.workflow.id}</InformationLabel>
          <InformationLabel label="Created">
            {dayjs(props.workflow.createdAt).format('L')}
          </InformationLabel>
          <Box sx={{ flexGrow: 1 }}></Box>
        </Box>
      </Paper>
    </Box>
  );
}
