import EditIcon from '@mui/icons-material/Edit';
import { Button, Card, Container, IconButton, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import authContext from 'react-shared-library/auth/authContext';
import ErrorAlert from 'react-shared-library/components/common/ErrorAlert';
import InformationLabel from 'react-shared-library/components/common/InformationLabel';
import GroupService from 'react-shared-library/rtdapi-sdk/group-service';
import { RtdGroup } from 'react-shared-library/types';

import TransferOwnershipDialog from './TransferOwnershipDialog';

export default function GroupSettingsContainer(props: {
  group: RtdGroup | null;
  onGroupChange?: (group: RtdGroup) => void;
}) {
  const groupService = useContext(GroupService.context);
  const auth = useContext(authContext);
  const { groupId } = useParams();

  const [error, setError] = useState<string | null>(null);
  const [transferOwnershipOpen, setTransferOwnershipOpen] = useState(false);

  const handleInvite = (email: string, message: string) => {
    if (!groupService || !groupId) return;
    groupService
      .transferOwnership(groupId, email, message)
      .then(() => setTransferOwnershipOpen(false))
      .catch((error) => setError(error.message));
  };

  const handleEdit = () => {
    if (!groupService || !groupId) return;
    const name = window.prompt('Group Name', props.group?.name);
    if (!name) return;
    groupService
      .renameGroup(groupId, name)
      .then((group) => props.onGroupChange?.(group))
      .catch((error) => setError(error));
  };

  return (
    <Container maxWidth="lg">
      <ErrorAlert>{error}</ErrorAlert>
      {transferOwnershipOpen && props.group && (
        <TransferOwnershipDialog
          group={props.group}
          open
          onInvite={handleInvite}
        />
      )}
      <Typography
        variant="h4"
        component="div"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {'Settings '}
      </Typography>
      {props.group && (
        <React.Fragment>
          <Card sx={{ p: 2, mb: 2 }}>
            <InformationLabel label="Group ID">
              {props.group.id}
            </InformationLabel>
            <InformationLabel
              label="Group Name"
              endAdornments={
                <IconButton sx={{ p: 0 }} size="small" onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
              }
            >
              {props.group.name}
            </InformationLabel>
          </Card>
          {auth?.credentials?.userId === props.group.ownerId && (
            <Card sx={{ p: 2, mb: 2 }}>
              <Typography variant="h5">Danger Zone</Typography>
              <p>
                This will transfer the ownership of <b>{props.group.name}</b> to
                new user.
              </p>
              <Button
                color="error"
                variant="contained"
                onClick={() => setTransferOwnershipOpen(true)}
              >
                Transfer Ownership
              </Button>
            </Card>
          )}
        </React.Fragment>
      )}
    </Container>
  );
}
