import { Alert, Snackbar } from '@mui/material';

interface ErrorAlertProps {
  children: React.ReactNode;
  onClose?: () => void;
}

export default function ErrorAlert(props: ErrorAlertProps) {
  return (
    <Snackbar
      open={!!props.children}
      autoHideDuration={6000}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity="error">{props.children}</Alert>
    </Snackbar>
  );
}
