import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import React, { useContext, useEffect, useState } from 'react';
import ErrorAlert from 'react-shared-library/components/common/ErrorAlert';
import AddWorkflowToGroupDialog from 'react-shared-library/components/group/AddWorkflowToGroupDialog';
import WorkflowBox from 'react-shared-library/components/workflow/WorkflowBox';
import GroupService from 'react-shared-library/rtdapi-sdk/group-service';
import { RtdGroup, RtdWorkflow } from 'react-shared-library/types';

export default function GroupWorkflowContainer(props: {
  group: RtdGroup | null;
}) {
  const groupService = useContext(GroupService.context);
  const [error, setError] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [workflows, setWorkflows] = useState<RtdWorkflow[] | null>(null);

  useEffect(() => {
    if (!props.group || !groupService) return;
    groupService
      .getWorkflowForGroup(props.group.id)
      .then(setWorkflows)
      .catch((error) => setError(error.message));
  }, [props.group, groupService]);

  const handleRemove = (workflow: RtdWorkflow) => {
    if (!groupService || !props.group?.id) return;
    groupService
      .removeWorkflowFromGroup(props.group.id, workflow.id)
      .then(() =>
        setWorkflows(workflows?.filter((w) => w.id !== workflow.id) ?? [])
      )
      .catch((error) => setError(error.message));
  };

  const handleAddWorkflow = (workflow: RtdWorkflow) => {
    setDialogOpen(false);
    setWorkflows([...(workflows ?? []), workflow]);
  };

  return (
    <Container maxWidth="lg">
      <ErrorAlert>{error}</ErrorAlert>
      {props.group && dialogOpen && (
        <AddWorkflowToGroupDialog
          group={props.group}
          open
          onAdded={handleAddWorkflow}
          onClose={() => setDialogOpen(false)}
        />
      )}
      <Typography variant="h4" component="div">
        Workflows
      </Typography>
      <Box sx={{ display: 'flex', gap: 8 }}>
        <Box
          sx={{
            flex: '75% 0 0',
            display: 'grid',
            rowGap: 2,
            columnGap: 2,
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr) )',
          }}
        >
          {workflows?.map((workflow, i) => (
            <WorkflowBox key={i} onRemove={handleRemove} workflow={workflow} />
          ))}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setDialogOpen(true)}
          >
            Add
          </Button>
          <p>
            These are workflows attached to the group. This does not include
            workflows that are available to users through In-App Purchases.
          </p>
        </Box>
      </Box>
    </Container>
  );
}
