import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';

interface HeaderProps {
  routerLink?: string;
  routerLabel?: string;
  children?: React.ReactNode;
}

export default function Header(props: HeaderProps) {
  const showLink = props.routerLink && props.routerLabel;
  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ height: '1.75em' }}>
        {showLink && (
          <Link href={props.routerLink} underline="none">
            <Typography variant="label">← {props.routerLabel}</Typography>
          </Link>
        )}
      </Box>
      <Typography variant="h1">{props.children}</Typography>
    </Box>
  );
}
