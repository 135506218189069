import {
  RtdEntitlement,
  RtdGroup,
  RtdUser,
  RtdWorkflow,
} from 'react-shared-library/types';

export const MOCK_GROUPS: RtdGroup[] = [
  {
    id: '000-000',
    name: 'Oyster Fishing Co',
    ownerId: 'bbb-bbb',
  },
  {
    id: '000-001',
    name: 'Lobster Fishing Co',
    ownerId: 'bbb-bbb',
  },
];

export const MOCK_USERS: RtdUser[] = [
  {
    id: '000-000',
    email: 'john.smith@example.com',
    details: { name: 'John Smith' },
  },
  { id: '000-001', email: 'jane.smith@example.com' },
];

export const MOCK_WORKFLOWS: RtdWorkflow[] = [
  {
    id: 'RTD-DW',
    name: 'Default Workflow',
    createdAt: new Date(),
    priority: 100,
  },
  {
    id: 'GARFO',
    name: 'Greater Atlantic Workflow',
    createdAt: new Date(),
    priority: 500,
  },
];

export const MOCK_ENTITLEMENTS: RtdEntitlement[] = [
  {
    id: '1',
    entitlement: 'mock_entitlement.first',
    createdAt: new Date('2020-06-31'),
  },
  {
    id: '2',
    entitlement: 'mock_entitlement.second',
    createdAt: new Date('2022-01-03'),
    expiry: new Date('2021-11-28'),
  },
  {
    id: '3',
    entitlement: 'mock_entitlement.third',
    createdAt: new Date('2022-04-15'),
    expiry: new Date('2099-09-03'),
  },
];
