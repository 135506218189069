import { createContext } from 'react';

import { ICodeService } from '../types';
import { apiOrganisationToGroup } from './group-service';
import { request } from './request';

const DEFAULT_PAGINATION = 100;

// Expands the where query to use "ilike"
const expandWhereQuery = (where: {
  activationCode?: string;
  status?: string;
  createdAt?: string;
  comment?: string;
  createdBy?: string;
  redeemedBy?: string;
}) => {
  return {
    ...where,
    activationCode: where.activationCode
      ? { ilike: `%${where.activationCode}%` }
      : undefined,
    comment: where.comment ? { ilike: `%${where.comment}%` } : undefined,
  };
};

export default class CodeService implements ICodeService {
  static context = createContext<ICodeService | null>(null);

  constructor(private url: string, private accessToken: string) {}

  private async request(
    method: string,
    path: string,
    queryParams?: object,
    body?: object
  ) {
    return request(this.accessToken, this.url, method, path, queryParams, body);
  }

  async getCodes(
    page: number = 0,
    where: {
      activationCode?: string;
      status?: string;
      createdAt?: string;
      comment?: string;
      createdBy?: string;
      redeemedBy?: string;
    }
  ) {
    const response = await this.request('GET', '/api/v1/activation-codes', {
      filter: {
        limit: DEFAULT_PAGINATION,
        skip: DEFAULT_PAGINATION * page,
        where: expandWhereQuery(where),
        include: [
          { relation: 'creator', scope: { include: 'details' } },
          { relation: 'redeemer', scope: { include: 'details' } },
          {
            relation: 'invitations',
            scope: {
              include: {
                relation: 'organisation',
                scope: { include: 'details' },
              },
            },
          },
        ],
      },
    });
    const json = await response.json();
    return json.map((code: any) => ({
      activationCode: code.activationCode,
      status: code.status,
      comment: code.comment,
      mailchimpTemplate: code.mailchimpTemplate,
      createdAt: code.createdAt ? new Date(code.createdAt) : null,
      redeemedAt: code.redeemedAt ? new Date(code.redeemedAt) : null,
      groups: code.invitations.map((invitation: any) =>
        apiOrganisationToGroup(invitation.organisation)
      ),
      creator: code.creator,
      redeemer: code.redeemer,
    }));
  }

  async listCodes(where: {
    activationCode?: string;
    status?: string;
    createdAt?: string;
    comment?: string;
    createdBy?: string;
    redeemedBy?: string;
  }) {
    const response = await this.request(
      'GET',
      '/api/v1/activation-codes/count',
      { filter: { where: expandWhereQuery(where) } }
    );
    const { count } = await response.json();
    return count;
  }

  async createCodes(
    groups: string[],
    codes: string[],
    comment: string,
    createdBy: string,
    mailchimpTemplate?: string
  ) {
    const createCodesResponse = await this.request(
      'POST',
      '/api/v1/activation-codes',
      {},
      codes.map((code) => ({
        activationCode: code,
        status: 'Unclaimed',
        comment,
        mailchimpTemplate,
        createdBy,
      }))
    );
    if (!createCodesResponse.ok)
      throw new Error('Error creating activation codes');

    const invitations = codes.reduce(
      (ivs, code) => [
        ...ivs,
        ...groups.map((groupId) => ({
          status: 'Pending',
          roles: [{ role: 'ORG_MEMBER' }],
          organisationId: groupId,
          activationCode: code,
        })),
      ],
      [] as any
    );

    const createInvitationsResponse = await this.request(
      'POST',
      '/api/v1/Invitations',
      {},
      invitations
    );
    if (!createInvitationsResponse.ok)
      throw new Error('Error creating activation code invitations');

    // Query and return only the newly created codes
    const response = await this.request('GET', '/api/v1/activation-codes', {
      filter: {
        where: { activationCode: { inq: codes } },
        include: [
          { relation: 'creator', scope: { include: 'details' } },
          { relation: 'redeemer', scope: { include: 'details' } },
          {
            relation: 'invitations',
            scope: {
              include: {
                relation: 'organisation',
                scope: { include: 'details' },
              },
            },
          },
        ],
      },
    });
    const json = await response.json();
    return json.map((code: any) => ({
      activationCode: code.activationCode,
      status: code.status,
      comment: code.comment,
      mailchimpTemplate: code.mailchimpTemplate,
      createdAt: code.createdAt ? new Date(code.createdAt) : null,
      redeemedAt: code.redeemedAt ? new Date(code.redeemedAt) : null,
      groups: code.invitations.map((invitation: any) =>
        apiOrganisationToGroup(invitation.organisation)
      ),
      creator: code.creator,
      redeemer: code.redeemer,
    }));
  }

  async getCodesForGroup(groupId: string) {
    const invitationResponse = await this.request(
      'GET',
      '/api/v1/Invitations',
      {
        filter: {
          where: { organisationId: groupId },
          fields: ['activationCode'],
        },
      }
    );
    if (!invitationResponse.ok)
      throw new Error('Failed to get activation codes for the group');
    const invitationJson = await invitationResponse.json();
    const codes: string[] = invitationJson
      .map(({ activationCode }: any) => activationCode)
      .filter(Boolean);

    const response = await this.request('GET', '/api/v1/activation-codes', {
      filter: {
        where: { activationCode: { inq: codes } },
        include: [
          { relation: 'creator', scope: { include: 'details' } },
          { relation: 'redeemer', scope: { include: 'details' } },
          {
            relation: 'invitations',
            scope: {
              include: {
                relation: 'organisation',
                scope: { include: 'details' },
              },
            },
          },
        ],
      },
    });
    const json = await response.json();
    return json.map((code: any) => ({
      activationCode: code.activationCode,
      status: code.status,
      comment: code.comment,
      mailchimpTemplate: code.mailchimpTemplate,
      createdAt: code.createdAt ? new Date(code.createdAt) : null,
      redeemedAt: code.redeemedAt ? new Date(code.redeemedAt) : null,
      groups: code.invitations.map((invitation: any) =>
        apiOrganisationToGroup(invitation.organisation)
      ),
      creator: code.creator,
      redeemer: code.redeemer,
    }));
  }

  private getRandomString = (length: number) => {
    const randomChars = 'ABCDEFGHJKMNPQRSTWXYZ23456789'; // removed some ambiguous characters
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  };

  generateCode = () => this.getRandomString(10);
}
