import { Paper } from '@mui/material';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const StyledMuiDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ drawerwidth: number }>(({ theme, open, drawerwidth }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerwidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface AppDrawerProps extends MuiDrawerProps {
  drawerWidth: number;
}

export default function AppDrawer(props: AppDrawerProps) {
  return (
    <Paper elevation={12} sx={{ zIndex: '100' }}>
      <StyledMuiDrawer
        variant="permanent"
        open={props.open}
        drawerwidth={props.drawerWidth}
        sx={{ height: '100%' }}
      >
        {props.children}
      </StyledMuiDrawer>
    </Paper>
  );
}
