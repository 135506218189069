import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import CopyableValue from './CopyableValue';
import RenderedValue from './RenderedValue';

interface InformationProps {
  label: string;
  copyable?: boolean;
  children?: React.ReactNode;
  endAdornments?: React.ReactNode;
}

export default function InformationLabel(props: InformationProps) {
  return (
    <Box sx={{ display: 'flex', width: '100%', pb: 1 }}>
      <Typography variant="label" component="p" sx={{ pr: 1 }}>
        {props.label}:
      </Typography>
      <Typography variant="body1" component="span" sx={{ flexGrow: 1 }}>
        {props.copyable ? (
          <CopyableValue endAdornments={props.endAdornments}>
            {props.children}
          </CopyableValue>
        ) : (
          <RenderedValue endAdornments={props.endAdornments}>
            {props.children}
          </RenderedValue>
        )}
      </Typography>
    </Box>
  );
}
