import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import authContext from 'react-shared-library/auth/authContext';
import ErrorAlert from 'react-shared-library/components/common/ErrorAlert';
import signedInUserContext from 'react-shared-library/context/user-context';
import GroupService from 'react-shared-library/rtdapi-sdk/group-service';
import {
  RtdGroup,
  RtdUser,
  RtdUserWithPermissions,
} from 'react-shared-library/types';

import CreateUserDialog from './CreateUserDialog';
import EditUserDialog from './EditUserDialog';
import GroupUserTable from './GroupUserTable';
import InviteUserDialog from './InviteUserDialog';

export default function GroupUserContainer(props: { group: RtdGroup | null }) {
  const auth = useContext(authContext);
  const groupService = useContext(GroupService.context);
  const signedInUserCtx = useContext(signedInUserContext);
  const { groupId } = useParams();

  const [isManagementAuthorized, setIsManagementAuthorized] = useState<
    null | boolean
  >(null);
  const [isGroupAdmin, setIsGroupAdmin] = useState<null | boolean>(null);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const [editUser, setEditUser] = useState<RtdUserWithPermissions | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<RtdUserWithPermissions[] | null>(null);

  useEffect(() => {
    if (!signedInUserCtx || !groupId) return;
    signedInUserCtx
      .isManagementAuthorized(groupId)
      .then(setIsManagementAuthorized);
    signedInUserCtx.isGroupAdmin(groupId).then(setIsGroupAdmin);
  }, [signedInUserCtx, groupId]);

  useEffect(() => {
    if (!groupService) return;
    if (!groupId) return;
    groupService
      .getUsersForGroup(groupId)
      .then(setUsers)
      .catch((error) => setError(error.message));
  }, [groupService, groupId]);

  const handleInvite = (email: string, message: string) => {
    if (!groupService) return;
    if (!groupId) return;
    setInviteUserDialogOpen(false);
    groupService
      .inviteUser(groupId, email, message)
      .catch((error) => setError(error.message))
      .finally(() => setInviteUserDialogOpen(false));
  };

  const handleCreate = (email: string, password: string, name: string) => {
    if (!groupService) return;
    if (!groupId) return;
    setInviteUserDialogOpen(false);
    groupService
      .createUser(groupId, email, password, name)
      .then(() =>
        groupService
          .getUsersForGroup(groupId)
          .then(setUsers)
          .catch((error) => setError(error.message))
      )
      .catch((error) => setError(error.message))
      .finally(() => setCreateUserDialogOpen(false));
  };

  const handleRemoveUser = (user: RtdUser) => {
    if (!groupService || !groupId || !props.group) return;
    if (
      !window.confirm(
        `Are you sure you want to remove the user from ${props.group.name}?`
      )
    )
      return;
    groupService
      .removeUserFromGroup(user.id, groupId)
      .then(() => {
        setUsers([...(users ?? [])].filter((u) => u.id !== user.id));
      })
      .catch((error) => setError(error.message));
  };

  const handleEditUser = (email: string, name: string, isManager: boolean) => {
    if (!groupService || !groupId || !editUser) return;
    groupService
      .editUser(groupId, editUser.id, email, name, isManager)
      .then(() => groupService.getUsersForGroup(groupId))
      .then(setUsers)
      .catch((error) => setError(error.message))
      .finally(() => setEditUser(null));
  };

  return (
    <Container maxWidth="lg">
      <ErrorAlert>{error}</ErrorAlert>
      {props.group && (
        <React.Fragment>
          <InviteUserDialog
            group={props.group}
            open={inviteUserDialogOpen}
            onCancel={() => setInviteUserDialogOpen(false)}
            onInvite={handleInvite}
          />
          <CreateUserDialog
            group={props.group}
            open={createUserDialogOpen}
            onCancel={() => setCreateUserDialogOpen(false)}
            onCreate={handleCreate}
          />
          {editUser && (
            <EditUserDialog
              user={editUser}
              group={props.group}
              open
              onCancel={() => setEditUser(null)}
              onEdit={handleEditUser}
            />
          )}
        </React.Fragment>
      )}
      <Typography
        variant="h4"
        component="div"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {'Members '}
        {!!users?.length && `(${users.length})`}
        <Box>
          {isManagementAuthorized && (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setInviteUserDialogOpen(true)}
            >
              Invite
            </Button>
          )}
          {isGroupAdmin && (
            <Button
              sx={{ ml: 2 }}
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setCreateUserDialogOpen(true)}
            >
              Add
            </Button>
          )}
        </Box>
      </Typography>
      <GroupUserTable
        users={users}
        ownUserId={auth?.credentials?.userId}
        onRemoveUser={isManagementAuthorized ? handleRemoveUser : undefined}
        onEditUser={isGroupAdmin ? setEditUser : undefined}
        showChips
      ></GroupUserTable>
    </Container>
  );
}
