import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import ErrorAlert from 'react-shared-library/components/common/ErrorAlert';
import ReportDateFilter from 'react-shared-library/components/reports/ReportDateFilter';
import ReportTable from 'react-shared-library/components/reports/ReportTable';
import ReportViewDialog from 'react-shared-library/components/reports/ReportViewDialog';
import { RtdGroup } from 'react-shared-library/types';

import ReportService from '../common/report-service';

export default function GroupReportContainer(props: {
  group: RtdGroup | null;
}) {
  const reportService = useContext(ReportService.context);

  const [reports, setReports] = useState<any[] | null>(null);
  const [report, setReport] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleFilterUpdate = (from: Date, until: Date) => {
    if (!reportService) return;
    if (!props.group) return;

    reportService
      .getReportsForGroup(props.group.id, from, until)
      .then(setReports)
      .catch((error) => setError(error.message));
  };

  useEffect(() => {
    if (!props.group) return;
    if (!reportService) return;

    reportService
      .getReportsForGroup(
        props.group.id,
        dayjs().startOf('day').toDate(),
        dayjs().endOf('day').toDate()
      )
      .then(setReports)
      .catch((error) => setError(error.message));
  }, [props.group, reportService]);

  const handleDownload = () => {
    if (!reports) return;
    const tempElement = window.document.createElement('a');
    // Filter out reports that do not have an attached event (the actual activity data)
    const reportsWithData = reports.filter((report) => !!report.event);
    // If there were any skipped, let the user know
    if (reports.length > reportsWithData.length) {
      setError(
        `Skipped exporting ${
          reports.length - reportsWithData.length
        } due to missing data`
      );
    }
    // Map out the actual activity data
    const activities = reportsWithData.map((report) => report.event);
    tempElement.href = window.URL.createObjectURL(
      new Blob([JSON.stringify(activities)])
    );
    tempElement.download = 'export.json';
    document.body.appendChild(tempElement);
    tempElement.click();
    document.body.removeChild(tempElement);
  };

  return (
    <React.Fragment>
      <ErrorAlert>{error}</ErrorAlert>
      {report && (
        <ReportViewDialog
          open
          report={report}
          onClose={() => setReport(null)}
        />
      )}
      <Typography
        variant="h4"
        component="div"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {'Reports '}
      </Typography>
      <ReportDateFilter onUpdate={handleFilterUpdate} />
      <ReportTable reports={reports} onSelectReport={setReport} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!!reports?.length && (
          <Button
            sx={{ my: 2 }}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={handleDownload}
          >
            Download
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
