import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import GroupService from 'react-shared-library/rtdapi-sdk/group-service';

import useAuth from './useAuth';

interface RouteGuardProps {
  children: React.ReactNode;
  // onUnauthenticated is a function that should return elements to render when the app is unauthenticated
  onUnauthenticated: Function;
}

export default function RouteGuard(props: RouteGuardProps) {
  const auth = useAuth();
  const groupService = useContext(GroupService.context);

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!auth?.credentials) {
      const authSuccessString = searchParams.get('authSuccess');
      if (authSuccessString) {
        const { userId, accessToken } = JSON.parse(authSuccessString);
        auth?.login(userId, accessToken);
        const newParams = new URLSearchParams(searchParams.toString());
        newParams.delete('authSuccess');
        setSearchParams(newParams);
        return;
      }

      // Save the JWT to be rehydrated upon login
      const jwt = searchParams.get('jwt');
      if (jwt) localStorage.setItem('jwt', jwt);

      props.onUnauthenticated();
    }
  }, [searchParams, setSearchParams, auth, props]);

  useEffect(() => {
    const jwt = searchParams.get('jwt') || localStorage.getItem('jwt');
    if (!jwt) return;
    if (!groupService) return;
    const { organisationId, type } = jwtDecode(jwt) as any;

    setLoading(true);
    if (type === 'OWNERSHIP_INVITE') {
      groupService
        .acceptOwnershipInvitation(organisationId, jwt)
        .finally(() => {
          const newParams = new URLSearchParams(searchParams.toString());
          newParams.delete('jwt');
          setSearchParams(newParams);
          setLoading(false);
          localStorage.removeItem('jwt');
        });
    } else if (type === 'MEMBERSHIP_INVITE') {
      groupService.acceptInvitation(organisationId, jwt).finally(() => {
        const newParams = new URLSearchParams(searchParams.toString());
        newParams.delete('jwt');
        setSearchParams(newParams);
        setLoading(false);
        localStorage.removeItem('jwt');
      });
    } else {
      return;
    }
  }, [groupService, setSearchParams, searchParams]);

  if (!auth?.credentials || loading) {
    return <React.Fragment></React.Fragment>;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
}
