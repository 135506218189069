/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from 'react';
import authContext from '../auth/authContext';
import CodeService from './code-service';
import GroupService from './group-service';
import MockGroupService from './group-service.mock';
import UserService from './user-service';
import MockUserService from './user-service.mock';
import WorkflowService from './workflow-service';
import MockWorkflowService from './workflow-service.mock';

import MockCodeService from './code-service.mock';

export interface ProvideServicesProps {
  rtdApiUrl: string;
  children: React.ReactNode;
}

export default function ProvideServices(props: ProvideServicesProps) {
  const auth = useContext(authContext);

  let userService: UserService | null = null;
  let groupService: GroupService | null = null;
  let codeService: CodeService | null = null;
  let workflowService: WorkflowService | null = null;
  if (auth?.credentials) {
    userService = new UserService(
      props.rtdApiUrl,
      auth.credentials.accessToken
    );
    groupService = new GroupService(
      props.rtdApiUrl,
      auth.credentials.accessToken
    );
    codeService = new CodeService(
      props.rtdApiUrl,
      auth.credentials.accessToken
    );
    workflowService = new WorkflowService(
      props.rtdApiUrl,
      auth.credentials.accessToken
    );
  }

  return (
    <UserService.context.Provider value={userService}>
      <GroupService.context.Provider value={groupService}>
        <CodeService.context.Provider value={codeService}>
          <WorkflowService.context.Provider value={workflowService}>
            {props.children}
          </WorkflowService.context.Provider>
        </CodeService.context.Provider>
      </GroupService.context.Provider>
    </UserService.context.Provider>
  );
}

interface ProvideMockServicesProps {
  children: React.ReactNode;
}

export function ProvideMockServices(props: ProvideMockServicesProps) {
  return (
    <UserService.context.Provider value={new MockUserService()}>
      <GroupService.context.Provider value={new MockGroupService()}>
        <CodeService.context.Provider value={new MockCodeService()}>
          <WorkflowService.context.Provider value={new MockWorkflowService()}>
            {props.children}
          </WorkflowService.context.Provider>
        </CodeService.context.Provider>
      </GroupService.context.Provider>
    </UserService.context.Provider>
  );
}
