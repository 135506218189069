import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import InformationLabel from 'react-shared-library/components/common/InformationLabel';
import VersionState from 'react-shared-library/components/workflow/VersionState';
import { MOCK_WORKFLOWS } from 'react-shared-library/rtdapi-sdk/mockData';

import Header from 'react-shared-library/components/common/Header';

/**
 * Things that need to eventually go on this component:
 *
 * - List of all versions
 * - Pending approval status of each version
 * - Early access of each version
 * - Workflow Capabilities
 *
 */

export default function ViewWorkflowContainer() {
  const workflow = MOCK_WORKFLOWS[0];
  return (
    <Container maxWidth="lg">
      <Header routerLink="/workflows" routerLabel="Workflows">
        {workflow.name}
      </Header>
      <Paper sx={{ p: 2, mb: 2 }}>
        <InformationLabel label="ID">RTD-DW</InformationLabel>
        <InformationLabel label="Name">Default Workflow</InformationLabel>
      </Paper>
      <Typography variant="h3">Versions</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Stage</TableCell>
              <TableCell>Approval</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>3.2.0</TableCell>
              <TableCell>{new Date().toLocaleDateString()}</TableCell>
              <TableCell>
                <VersionState state="EARLY_ACCESS" />
              </TableCell>
              <TableCell>Pending Approval</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3.2.0</TableCell>
              <TableCell>{new Date().toLocaleDateString()}</TableCell>
              <TableCell>
                <VersionState state="EARLY_ACCESS" />
              </TableCell>
              <TableCell>Approved</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3.1.2</TableCell>
              <TableCell>{new Date().toLocaleDateString()}</TableCell>
              <TableCell>
                <VersionState state="LIVE" />
              </TableCell>
              <TableCell>Approved</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3.1.1</TableCell>
              <TableCell>{new Date().toLocaleDateString()}</TableCell>
              <TableCell>
                <VersionState state="LIVE" />
              </TableCell>
              <TableCell>Approved</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2.13.5</TableCell>
              <TableCell>{new Date().toLocaleDateString()}</TableCell>
              <TableCell>
                <VersionState state="LIVE" />
              </TableCell>
              <TableCell>Approved</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
